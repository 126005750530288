import axios from 'axios';
import React, { Component } from 'react';
import { Table, Alert } from 'reactstrap';
import * as message from '../../utils/messages/message';
import * as utility from './utility';
import Paginacion from '../Pagination';

class TableHistoricoCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination_poliza: { count: 1, results: [] },
            page_actual: 1,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.methodGET_API(utility.url_historico_poliza, 'pagination_poliza');
    }
    handlePage = (page_actual) => {
        if (this.props.change_table === "0")
            this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_poliza, 'pagination_poliza'); });
        else
            return null;
    }
    handleTipo_count = (tipo_app) => {
        if (tipo_app === "0")
            return this.state.pagination_poliza.count;
        else
            return 1;
    }
    methodGET_API = (url, state) => {
        let url_intern = url + `?page=${this.state.page_actual}`;
        axios.get(url_intern)
            .then(response => {
                if (response.status >= 200 && response.status < 400) {
                    switch (state) {
                        case 'pagination_poliza':
                            if (this._isMounted === true) {
                                let arr = [];
                                arr = utility.Burbuja_Poliza(response.data.results);
                                this.setState({
                                    pagination_poliza: { count: response.data.count, results: arr }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
            });
    }
    render() {
        return (
            <React.Fragment>
                <div className="overflow-auto tm-table">
                    {
                        this.props.change_table === "0" ?
                            (<Table size="sm" id="tabla_historico" bordered hover responsive>
                                <thead className="text-center header-table">
                                    <tr>
                                        <th>Número</th>
                                        <th>Tipo</th>
                                        <th>Subtipo</th>
                                        <th>Concepto</th>
                                        <th>Cargo</th>
                                        <th>Abono</th>
                                        <th>Observaciones</th>
                                        <th>Usuario</th>
                                    </tr>
                                </thead>
                                <tbody className="body-table hover-gray">
                                    {this.state.pagination_poliza.results.map((poliza, index, arreglo) => {
                                        let numero = "";
                                        if (index !== 0)
                                            numero = arreglo[index - 1].numero;
                                        return (<tr>
                                            {/*
																						{numero === poliza.numero ? null :
                                                (<td rowSpan={utility.repeticion_poliza(poliza.numero, this.state.pagination_poliza.results)} >
                                                    <p className="text-center mb-0">{poliza.numero}</p>
                                                </td>)
                                            }
																						*/}
																						<td ><p className="text-center mb-0">{poliza.numero}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.tipo}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.subtipo}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.concepto}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.cargo}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.abono}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.observaciones}</p></td>
                                            <td ><p className="text-center mb-0">{poliza.usuario}</p></td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>) :
                            (<Alert color="danger" className="mt-5 col-lg-8 col-12 mx-auto">
                                <h2 className="mb-0 text-center">Debe seleccionar una aplicación para ver su tabla de histórico</h2>
                            </Alert>)
                    }
                </div>
                {this.props.change_table === 0 ? (null) : (
                    <Paginacion actual={this.state.page_actual} count={this.handleTipo_count(this.props.change_table)} paginate={this.handlePage} />
                )}
            </React.Fragment>);
    }
}
export default TableHistoricoCon;