import React, { Component } from 'react';
import SearchBar from './SearchBar';
import Table from './Table';
import * as permisos from '../../utils/permisos/permisos';
import axios from 'axios';
import * as message from '../../utils/messages/message';
import * as utility from './utility'

class HistoricoOperacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change_table: 0,
      permisos: {
        alerta: permisos.obtener_permisos_by_app('alertahistorico', localStorage.getItem('perm')),
      },
      clientes: [],
      consolidadoras: [],
      proveedores: [],
			phxcc: [],
			beneficiario: [],
    }
  }
  changeTable = (num_table) => {
    this.setState({ change_table: num_table })
  }

  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_clientes, "clientes");
    this.methodGET_API(utility.url_consolidadoras, "consolidadoras");
    this.methodGET_API(utility.url_proveedor, "proveedores");
		this.methodGET_API(utility.url_phxcc, "phxcc");
		this.methodGET_API(utility.url_beneficiarios, "beneficiario");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data.results });
              break;
            case "consolidadoras":
              if (this._isMounted === true)
                this.setState({ consolidadoras: response.data.results });
              break;
            case "proveedores":
              if (this._isMounted === true)
                this.setState({ proveedores: response.data.results });
              break;
						case "phxcc":
							if (this._isMounted === true)
								this.setState({ phxcc: response.data.results });
							break;
						case "beneficiario":
							if (this._isMounted === true)
								this.setState({ beneficiario: response.data.results });
							break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <SearchBar changeTable={this.changeTable} permisos={this.state.permisos} />
        <Table
          change_table={this.state.change_table}
          clientes={this.state.clientes}
          consolidadoras={this.state.consolidadoras}
          proveedores={this.state.proveedores}
					phxcc={this.state.phxcc}
					beneficiario={this.state.beneficiario}
        />
      </div>);
  }

}
export default HistoricoOperacion;