/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos. 
*/
import axios from "axios";
import React, { Component } from "react";
import * as message from "./messages/messages";
import SearchBar from "./SearchBar";
import TableSol from "./Table";
import * as utility from "./utils/utility";
import * as permisos from "../../utils/permisos/permisos";

class SolAnticipo extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      referencias: [],
      acutalizar: 0,
      actualizar_pago: 0,
      checked: [],
      sum_solicitudes: 0,
      sum_ingresos: 0,
      sum_contribuciones: 0,
      sum_terceros: 0,

      ingresos: [],
      contribuciones: [],
      phxcc: [],
      patentes: [],

      permisos: permisos.obtener_permisos_by_app(
        "anticiposolicitud",
        localStorage.getItem("perm")
      ),
      on_cliente:
        localStorage.getItem("puesto") === "Cliente"
          ? localStorage.getItem("id_usr")
          : false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    //this.methodGET_API(utility.url_referencias, "referencias");
    this.methodGET_API(utility.url_clientes, "clientes");
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "contribuciones");
    this.methodGET_API(utility.url_phxcc, "phxcc");
    this.methodGET_API(utility.url_patentes, "patentes");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data });
              break;
            case "referencias":
              if (this._isMounted === true)
                this.setState({ referencias: response.data.results });
              break;
            case "ingresos":
              this.setState({
                ingresos: response.data.results,
              });
              break;
            case "contribuciones":
              this.setState({
                contribuciones: response.data.results,
              });
              break;
            case "phxcc":
              this.setState({
                phxcc: response.data.results,
              });
              break;
            case "patentes":
              this.setState({
                patentes: response.data.results,
              });
              break
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "clientes":
              if (this._isMounted === true)
                this.setState({ clientes: response.data });
              break;
            case "referencias":
              if (this._isMounted === true)
                this.setState({ referencias: response.data.results });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  update = (cambio) => {
    this.setState({ acutalizar: cambio });
  };
  update_pago = (cambio) => {
    this.setState({ actualizar_pago: cambio });
  };
  onCheckedSol = (data) => {
    this.setState(
      {
        checked: data,
      },
      () => {
        let sum_ing = utility.get_sum_solicitudes(this.state.checked, 0);
        let sum_con = utility.get_sum_solicitudes(this.state.checked, 1);
        let sum_ter = utility.get_sum_solicitudes(this.state.checked, 2);
        let sum_tot = utility.get_sum_solicitudes(this.state.checked, 3);
        this.setState({
          sum_solicitudes: sum_tot,
          sum_ingresos: sum_ing,
          sum_contribuciones: sum_con,
          sum_terceros: sum_ter,
        });
      }
    );
  };
  render() {
    return (
      <div className="container-fluid">
        <SearchBar
          referencias={this.state.referencias}
          clientes={this.state.clientes}
          patentes={this.state.patentes}
          update={this.update}
          update_pago={this.update_pago}
          checked={this.state.checked}
          sum_solicitudes={this.state.sum_solicitudes}
          sum_ingresos={this.state.sum_ingresos}
          sum_contribuciones={this.state.sum_contribuciones}
          sum_terceros={this.state.sum_terceros}
          on_cliente={this.state.on_cliente}
          permisos={this.state.permisos}
          ingresos={this.state.ingresos}
          contribuciones={this.state.contribuciones}
          phxcc={this.state.phxcc}
        />
        <TableSol
        clientes={this.state.clientes}
          actualizar={this.state.acutalizar}
          update={this.update}
          actualizar_pago={this.state.actualizar_pago}
          update_pago={this.update_pago}
          checked={this.onCheckedSol}
          on_cliente={this.state.on_cliente}
          permisos={this.state.permisos}
        />
      </div>
    );
  }
}
export default SolAnticipo;
