import { faCalculator, faCogs, faFileInvoiceDollar, faUniversity, faUniversalAccess, faBookOpen, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

export const OptionsArea = (props) => {
  var apps = [];
  if (props.area === 'operacion') {
    let appsOpe = [
      { name: 'Operaciones', url: '/operacion', name_app: 'operacion_app' },
      { name: 'Comprobación de PHXCC', url: '', name_app: '' },
      { name: 'Histórico', url: '/historico_operacion', name_app: 'historico' },
      { name: 'Pedimentos', url: '/pedimentos', name_app: 'pedimento_app' }
    ];

    const puesto = localStorage.getItem('puesto');
    if (puesto && puesto === 'Transportista') {
      appsOpe.unshift({ name: 'Notificación De Embarque', url: '/transportista', name_app: 'alerta_app' });
    } else {
      appsOpe.unshift({ name: 'Notificación De Embarque', url: '/alerta', name_app: 'alerta_app' });
    }

    apps = [...appsOpe];
  }

  if (props.area === 'bancos') {
    let appsBan = [
      { name: 'Solicitud De Depósito', url: '/solicitud_deposito', name_app: 'anticipo_solicitud_app' },
      { name: 'Anticipos', url: '/anticipos', name_app: 'anticipo_app' },
      { name: 'Pagos A Terceros', url: '/pagos_a_terceros', name_app: 'pagotercero_app' },
      { name: 'Cheques y tranferencias', url: '', name_app: '' },
      { name: 'Notificación De Depósito', url: '/notificacion_deposito', name_app: 'notificacion_deposito_app' },
      { name: 'Consiliación bancaria', url: '', name_app: '' },
      { name: 'Histórico', url: '/historico_bancos', name_app: 'historico' },
      { name: 'Transacciones', url: '/transacciones', name_app: 'transacciones_app' }];
    apps = [...appsBan];
  }
  if (props.area === 'facturacion') {
    let appsFac = [
      { name: 'Cuentas De Gastos', url: 'cuentas_gastos', name_app: 'cuentas_gastos_app' },
      { name: 'Consolidación De facturas', url: '/consolidacionfacturas', name_app: 'cuentas_gastos_app' },
      { name: 'Facturación 4.0', url: '/facturacion', name_app: 'facturas_app' },
      { name: 'Histórico', url: '', name_app: '' }];
    apps = [...appsFac];
  }
  if (props.area === 'contabilidad') {
    let appsCon = [
      { name: 'Polizas', url: '/polizas', name_app: 'poliza_app' },
      { name: 'Balance', url: '' },
      { name: 'Cuentas Auxiliares', url: '/cuentas_auxiliares', name_app: 'cuentas_auxiliares_app' },
      { name: 'Balanza de comprobación', url: '', name_app: '' },
      { name: 'Estado de Resultados', url: '', name_app: '' },
      { name: 'Clientes', url: '/clientes', name_app: 'clientes_app' },
      { name: 'Histórico', url: '/historico_contabilidad', name_app: 'historico' },
      { name: 'Conciliación Bancaria', url: '/conciliacion', name_app: 'cfdi_sat_app' }];
    apps = [...appsCon];
  }
  if (props.area === 'administracion') {
    let appsUtil = [
      { name: 'Empleados', url: '/empleados', name_app: 'cat_empleados' },
      { name: 'Departamentos', url: '/departamentos', name_app: 'cat_departamentos' },
      { name: 'Puestos', url: '/puestos', name_app: 'cat_puestos' },
      { name: 'Usuarios', url: '/usuarios', name_app: 'usuarios_app' }];
    apps = [...appsUtil];
  }
  if (props.area === 'catalogos') {
    let appsUtil = [
      { name: 'Agentes Aduanales', url: '/agenetes_aduanales', name_app: 'cat_agentes_aduanales' },
      { name: 'Beneficiarios', url: '/beneficiarios', name_app: 'cat_beneficiarios' },
      { name: 'Consolidadoras', url: '/consolidadoras', name_app: 'cat_consolidadoras' },
      { name: 'Contabilidad Electrónica', url: '/contabilidad_electronica', name_app: 'contabilidad_electronica_archivo' },
      { name: 'Cuentas Bancarias', url: '/cuentas_bancarias', name_app: 'cat_bancos' },
      { name: 'Diario', url: '/diario', name_app: 'cat_diario' },
      { name: 'Egresos', url: '/egresos', name_app: 'cat_egresos' },
      { name: 'Fracciones', url: '/fracciones', name_app: 'cat_fracciones' },
      { name: 'Ingresos', url: '/ingresos', name_app: 'cat_ingresos' },
      { name: 'Número Parte', url: '/numparte', name_app: 'cat_numparte' },
      { name: 'Pagos A Terceros', url: '/pagos_terceros', name_app: 'cat_phxcdc' },
      { name: 'Proveedores De Clientes', url: '/proveedores', name_app: 'cat_proveedores' }];
    apps = [...appsUtil];
  }
  let app_historico = null;
  let contador = 0;
  props.options.forEach(option => {
    if (option.includes('historico'))
      apps.forEach(app => {
        if (app.name_app === 'historico' && contador === 0) {
          contador++;
          app_historico = (
            <Link to={app.url} className="text-decoration-none" key="historico">
              <DropdownItem>{app.name}</DropdownItem>
            </Link>
          )
        }
      })
  });

  return (
    <React.Fragment>
      {
        // eslint-disable-next-line
        props.options.map(option => {
          for (let i = 0; i < apps.length; i++) {
            if (apps[i].name_app === option)
              return (
                <Link to={apps[i].url} className="text-decoration-none" key={i}>
                  <DropdownItem>{apps[i].name}</DropdownItem>
                </Link>);
          }
        })
      }
      {app_historico}
    </React.Fragment>
  );
}

const optionNav = (props) => {
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        {props.area === 'operacion' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faCogs} /> Operación
					</React.Fragment> : null}
        {props.area === 'bancos' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faUniversity} /> Bancos
					</React.Fragment> : null}
        {props.area === 'facturacion' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faFileInvoiceDollar} /> Facturación
					</React.Fragment> : null}
        {props.area === 'contabilidad' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faCalculator} /> Contabilidad
					</React.Fragment> : null}
        {props.area === 'utilerias' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faUniversalAccess} /> Utilerías
					</React.Fragment> : null}
        {props.area === 'catalogos' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faBookOpen} /> Catálogos
					</React.Fragment> : null}
        {props.area === 'administracion' ?
					<React.Fragment>
						<FontAwesomeIcon icon={faToolbox} /> Administración
					</React.Fragment> : null}
      </DropdownToggle>

      <DropdownMenu>
        <OptionsArea options={props.options} area={props.area} />
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
export default optionNav;