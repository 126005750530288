import axios from "axios";
import swal from "@sweetalert/with-react";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
  Spinner,
} from "reactstrap";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import MyInput from "../../../utils/custom/MyInput";
import MyDropzone from "../utils/MyDropzone";
import MyTextArea from "../utils/MyTextArea";

class ModalRectificar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      count: "",
      numero_pedimento: "",
      patente: "",
      aduana: "",
      referencia: "",
      infoGeneral: [],
      guias: [],
      facturas: [],
      archivo_select: [],
      subidaExitosa: false,
      rectificacion: "",
      pedimentoR: "",

      // - state de las observaciones
      observacionesR: "",
      Num_rectificacion: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleConsulta = () => {
    if (this.state.numero_pedimento.length === 0)
      message.message_modal("Advertencia", "Debe tener un número", 400);
    else {
      let data = { pedimento: this.state.numero_pedimento };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_consulta_pedimento, "consulta", data);
    }
  };

  handleCancelar = () => {
    if (this.state.numero_pedimento.length === 0)
      message.message_modal("Advertencia", "Debe tener un número", 400);
    else {
      let data = { pedimento: this.state.numero_pedimento };
      if (this.props.on_cliente !== false)
        data["cliente"] = this.props.on_cliente;
      this.methodPOST_API(utility.url_cancela_pedimento, "cancelar", data);
    }
  };

  handleNumeroPedimento = (e) => {
    let numero_pedimento = e.target.value;
    this.setState({
      numero_pedimento,
    });
  };

  handleImporte = (e) => {
    let importe = e.target.value;
    let data_modificacion = this.state.data_modificacion;
    data_modificacion["importe"] = importe;
    this.setState({
      importe,
      data_modificacion,
    });
  };

  handleErase = () => {
    this.setState({
      count: "",
      numero_pedimento: "",
      patente: "",
      aduana: "",
      referencia: "",
      infoGeneral: [],
      guias: [],
      facturas: [],
      archivo_select: [],
      subidaExitosa: false,
      rectificacion: "",
      pedimentoR: "",
      observacionesR: "",
      Num_rectificacion: "",
    });
    this.props.toogleRectificar();
  };
  /**
   * Función que permite hacer una petición de forma Get para colsultar un PDF.
   * @param {String} url URL a consultar.
   * @param {String} state Opción del case.
   * @param {Object} num Número del documento.
   */
  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              /* document.getElementById("dowload_pdf_consulta_anticipo").href =
                fileURL; */
              document.getElementById("window_pdf_consulta_anticipo").href =
                fileURL;

              const linkD = document.getElementById(
                "dowload_pdf_consulta_anticipo"
              );
              linkD.href = fileURL;
              linkD.download = `Anticipo${this.state.numero_pedimento}`;

              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "consulta":
              this.setState({
                patente: response.data.results.patente,
                aduana: response.data.results.aduana,
                referencia: response.data.results.referencia,
                pedimentoR: response.data.results.no_rectificacion,
              });
              this.setState({ count: parseInt(response.data.results.no_rectificacion) + 1 });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  modificaPUT_API = (url, state, data) => {
    let url_intern = url;
    axios
      .put(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "modifica":
              this.handleErase();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handleRectificar = () => {
    const { numero_pedimento } = this.state;

    if (
      this.state.numero_pedimento.length === 0 ||
      this.state.pedimentoR === ""
    )
      message.message_modal(
        "Advertencia",
        "Debe tener un número de pedimento",
        400
      );
    else {
      // if (this.state.observacionesR === "")
      //   this.setState({ observacionesR: "Sin observaciones" });
      const data = {
        numero_pedimento: numero_pedimento,
        rectificacion: this.state.Num_rectificacion,
        no_r: this.state.count.toString(),
        observaciones: this.state.observacionesR || "Sin observaciones",
      };

      // console.log("Data a enviar:", data);
      axios
        .post(utility.url_rectifica_pedimento, data)
        .then((response) => {
          // console.log("Se realizo la rectificacion");
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
          this.handleLimpiar();
        })
        .catch((error) => {
          if (error.response)
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
        });
    }
  };

  handleLimpiar = () => {
    this.setState({
      count: "",
      numero_pedimento: "",
      patente: "",
      aduana: "",
      referencia: "",
      infoGeneral: [],
      guias: [],
      facturas: [],
      archivo_select: [],
      subidaExitosa: false,
      rectificacion: "",
      pedimentoR: "",
      observacionesR: "",
      Num_rectificacion: "",
    });
  };

  handleArchivo = (file) => {
    // Guardar el archivo en el estado
    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_xml: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    this.setState({ archivo });
    this.setState({ archivo_select: [file] });
  };

  handleSubirArchivos = () => {
    if (this.state.numero_pedimento.length === 0)
      message.message_modal("Advertencia", "Debe tener un número", 400);
    else {
      if (this.state.referencia === "")
        this.handleConsulta();
      // Mostrar un spinner mientras se realiza la carga de archivos
      swal({
        title: "Subiendo archivo...",
        buttons: false,
        closeOnClickOutside: false,
        content: (
          <div>
            <Spinner
              color="success"
              style={{
                width: "5rem",
                height: "5rem",
                marginBottom: "26px",
                animation: "1.25s linear infinite spinner-border",
              }}
            >
              Loading...
            </Spinner>
          </div>
        ),
      });

      // Recuperar el archivo seleccionado
      const archivo = this.state.archivo;

      // Crear un objeto FormData y adjuntar el archivo con el nombre 'archivo'
      const formData = new FormData();
      formData.append("archivo", archivo);

      // Realizar la solicitud POST con el archivo adjunto
      axios
        .post("/DjangoWeb/PedimentosApp/carga_m", formData)
        .then((response) => {
          // Mostrar un mensaje con la respuesta de la solicitud
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
          this.setState({ Num_rectificacion: response.data.text });
          // Limpiar el estado después de la operación
          this.setState(
            {
              archivo: null, 
              subidaExitosa: true,
            },
            () => {
              swal.close();
            }
          );
        })
        .catch((error) => {
          // En caso de error, mostrar un mensaje con la información del error
          if (error.response) {
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
            this.setState({
              subidaExitosa: false,
            });
          }
        });
    }
  };

  // handlePedimentoR = (event) => {
  //   this.setState({ pedimentoR: event.target.value });
  // };

  handleEliminaArchivo = (index) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(index, 1);
    this.setState({ archivo_select: archivos_arr });
    this.setState({ archivo: null });
  };

  // - Metodos del manejo de las observaciones
  handleObservaciones = (e) => {
    let observaciones = e.target.value;
    this.setState({ observacionesR: observaciones });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_rectificar}
        toggle={this.handleErase}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleErase}>
          Rectificar Pedimento
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_pedimento_consulta"
                required
                value={this.state.numero_pedimento}
                onChange={this.handleNumeroPedimento}
                nameLabel="Número de Pedimento"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                nameLabel="Referencia"
                id="referencia_consulta"
                onChange={this.handleReferencia}
                value={this.state.referencia}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                nameLabel="Aduana"
                id="aduana_consulta"
                onChange={this.handleAduana}
                value={this.state.aduana}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                nameLabel="Patente"
                id="patente_consulta"
                onChange={this.handlePatente}
                value={this.state.patente}
                disabled={true}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-1"
                nameLabel="No. R"
                id="r"
                value={this.state.pedimentoR}
                disabled={true}
              />
            </FormGroup>
          </Form>

          <Form className="container-fluid">
            <FormGroup className="row mb-0 mt-2 mt-lg-0">
              <MyDropzone handleArchivo={this.handleArchivo} />
              <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                {this.state.archivo_select.map((archivo, index) => (
                  <Badge color="light" className="border">
                    {archivo.name}&nbsp;
                    <spam
                      style={{ cursor: "pointer" }}
                      id={index}
                      onClick={this.handleEliminaArchivo}
                    >
                      &times;
                    </spam>
                  </Badge>
                ))}
              </div>
              <Button
                color="primary"
                className="col-12"
                onClick={this.handleSubirArchivos}
              >
                Subir Archivo
              </Button>
            </FormGroup>
          </Form>
          <Form className="container-fluid">
            <FormGroup className="row mb-0 mt-3">
              <MyInput
                type="text"
                bootstrap="col-lg-11"
                nameLabel="Número de Rectificación"
                id="Pedimento_rectificar"
                // onChange={this.handlePedimentoR}
                value={this.state.Num_rectificacion}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-1"
                nameLabel="No. R"
                id="rR"
                value={this.state.count}
                disabled={true}
              />
            </FormGroup>
            {/*
            //- Elaboración del input de observaciones
            */}
            <FormGroup className="row mb-0">
            <MyTextArea
              bootstrap="col-12"
              id="observaciones_rectificacion"
              onChange={this.handleObservaciones}
              value={this.state.observacionesR}
              nameLabel="Observaciones"
            />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={this.handleRectificar}
            disabled={!this.state.subidaExitosa}
          >
            Rectificar
          </Button>
          <Button color="primary" onClick={this.handleErase}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalRectificar;
