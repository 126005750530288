import axios from 'axios';
import React, { Component } from 'react';
import {
	Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  Form,
  Card,
} from 'reactstrap';
import * as message from "../../../utils/messages/message";
import * as utility from "../utility";

class ModalOperacion extends Component {
  // Declaración de estado si el componente está montado o no
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      paginacion_pagos: { count: 1, results: [] },
    };
  }

  // Función que se ejecuta antes de renderizar el componente
  componentDidMount() {
    this._isMounted = true;
		this.handlePagosReferencia(this.props.referencia);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

	handlePagosReferencia = (referencia) => {
		const data = {
			referencia: referencia,
		};
		this.methodPOST_API(utility.url_historico_pagos, data);
	};

	// Función de consulta del pago seleccionado
  methodPOST_API = (url, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400){
					this.setState({
						paginacion_pagos: { count: response.data.results.length, results: response.data.results }
					});
				}
      })
      .catch((error) => {
				this.handleCerrar();
				if (error.response)
          message.message_modal(
						error.response.data.title,
						error.response.data.text,
						error.response.status
					);
      });
  };

	// Función para cerrar el modal
	handleCerrar = () => {
		this.handleLimpiar();
		this.props.functionToggleInfo();
	};

	// Limpiar datos
	handleLimpiar = () => {
		this.setState({
			paginacion_pagos: { count: 1, results: [] },
		});
	};

	// Función para obtener el status de la operación
	getStatus = (status) => {
		const status_operacion = {
			L: "Liquidado",
			P: "Pendiente",
			A: "Alta",
			K: "Cancelado",
			C: "Comprobado",
		}
		return status_operacion[status] || "Desconocido";
	};

	getTipoPHXCC = (tipo) => {
		const tipoString = tipo;
		const phxcc = this.props.phxcc.find(
			(item) => item.clave.toString() === tipoString
		);
		return phxcc ? phxcc.nombre : tipo;
	};

	getNameBeneficiario = (beneficiario) => {
		const beneficiarioString = beneficiario;
		const beneficiarioObj = this.props.beneficiario.find(
			(item) => item.id.toString() === beneficiarioString
		);
		return beneficiarioObj ? beneficiarioObj.nombre : beneficiario;
	};

  // Renderización de la vista
  render() {
		return (
			<Modal
				isOpen={this.props.toggleModal}
				toggle={this.handleCerrar}
				size="xl"
				backdrop="static"
			>
			<ModalHeader
				// toggle={this.handleCerrar}
			>
				Histórico de pagos - Referencia: {this.props.referencia}
			</ModalHeader>

			<ModalBody>
				<Table size="lg" id="tabla_historico" bordered hover responsive>
						<thead className="text-center header-table">
							<tr>
								<th>Fecha</th>
								<th>Fecha de Modificación</th>
								<th>Tipo de Pago</th>
								<th>Método de Pago</th>
								<th>Factura</th>
								<th>Cantidad</th>
								<th>Beneficiario</th>
								<th>Comprobante</th>
								<th>Estatus</th>
								<th>Observaciones</th>
								<th>Usuario</th>
							</tr>
						</thead>
						<tbody className="body-table">
							{this.state.paginacion_pagos.results.map((ope, index, arreglo) => {
								let operacion = "";
								if (index !== 0)
									operacion = arreglo[index - 1].folio;
								return (
								<tr key={index}>
									<td>
										<p className="text-center mb-0">{ope.fecha}</p>
									</td>
									<td>
										<p className="text-center mb-0">{ope.fecha_modificacion}</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{this.getTipoPHXCC(ope.tpo_pago)}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{ope.metodo_pago === "E" ? "Efectivo" : "Transferencia"}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{ope.factura === "" ? "Sin factura" : ope.factura}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">{ope.cantidad}</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{this.getNameBeneficiario(ope.beneficiario)}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{ope.comprobante === false ? "No cargado" : "Comprobante cargado"}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">
											{this.getStatus(ope.status)}
										</p>
									</td>
									<td>
										<p className="text-center mb-0">{ope.observaciones}</p>
									</td>
									<td>
										<p className="text-center mb-0">{ope.usuario}</p>
									</td>
								</tr>
								);
							})}
						</tbody>
				</Table>
			</ModalBody>

			<ModalFooter>
				<Button
					color="primary"
					onClick={this.handleCerrar}
				>
					Cerrar
				</Button>
			</ModalFooter>
		</Modal>
		);
  }
}

export default ModalOperacion;