import axios from 'axios';
import React, { Component } from 'react';
import { Table, Alert } from 'reactstrap';
import * as message from '../../utils/messages/message';
import * as utility from './utility';
import Paginacion from '../Pagination';
import * as permisos from '../../utils/permisos/permisos';
import ModalOperacion from './Modal/ModalOperacion';

class TableHistoricoOpe extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pagination_alerta: { count: 1, results: [] },
      // pagination_pedimentos: { count: 1, results: [] },   //* Unused
      pagination_operaciones: { count: 1, results: [] },
      page_actual: 1,
			toggle_info_pago: false,
			referenciaSeleccionada: "",
    };
		this.toggleInfoPago = this.toggleInfoPago.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_historico_alerta, 'pagination_alerta');
    // this.methodGET_API(utility.url_historico_pedimentos, 'pagination_pedimentos');  //* Unused
    this.methodGET_API(utility.url_historico_operaciones, 'pagination_operaciones');
  }

  handlePage = (page_actual) => {
    if (this.props.change_table === "0")
      this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_alerta, 'pagination_alerta'); });
    // else if (this.props.change_table === "1") //* Unused
    //   this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_pedimentos, 'pagination_pedimentos'); });
    else if (this.props.change_table === "2")
      this.setState({ page_actual }, () => { this.methodGET_API(utility.url_historico_operaciones, 'pagination_operaciones'); });
    else
      return null;
  }

  handleTipo_count = (tipo_app) => {
    if (tipo_app === "0")
      return this.state.pagination_alerta.count;
    // else if (tipo_app === "1") //* Unused
    //   return this.state.pagination_pedimentos.count;
    else if (tipo_app === "2")
      return this.state.pagination_operaciones.count;
    else
      return 1;
  }

  methodGET_API = (url, state) => {
    let url_intern = url + `?page=${this.state.page_actual}`;
    axios.get(url_intern)
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case 'pagination_alerta':
              if (this._isMounted === true) {
                let arr = [];
                arr = utility.Burbuja_Alerta(response.data.results);
                this.setState({
                  pagination_alerta: { count: response.data.count, results: arr }
                });
              }
              break;
            // * Unused
            // case 'pagination_pedimentos':
            //   if (this._isMounted === true) {
            //     this.setState({
            //       pagination_pedimentos: { count: response.data.count, results: response.data.results }
            //     });
            //   }
            //   break;
            case 'pagination_operaciones':
              if (this._isMounted === true) {
                this.setState({
                  pagination_operaciones: { count: response.data.count, results: response.data.results }
                });
              }
              break;
            default:
              break;
          }
        }
      })
      .catch(error => {
        if (error.response)
          message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
      });
  }

  getClienteName = (numero) => {
    let numeroString = numero.toString();
    while (numeroString.length < 4) {
      numeroString = '0' + numeroString;
    }
    const cliente = this.props.clientes.find(
      (item) => item.numero === numeroString
    );
    return cliente ? cliente.nombre : numero;
  };

  getConsolidadoraName = (numero) => {
    const numeroString = numero.toString();
    const consolidadora = this.props.consolidadoras.find(
      (item) => item.numero === numeroString
    );
    return consolidadora ? consolidadora.nombre : numero;
  };

  getProveedorName = (numero) => {
    const numeroString = numero;
    const proveedor = this.props.proveedores.find(
      (item) => item.numero.toString() === numeroString
    );
    return proveedor ? proveedor.nombre : numero;
  }

  StatusName = (status) => {
    switch (status) {
      case "A":
        return "Alta";
      case "PR":
        return "Pago realizado";
      case "P":
        return "Pedimento generado";
      case "D":
        return "Despachado";
      case "F":
        return "Facturado";
      case "E":
        return "Entregado";
      default:
        return "Desconocido";
    }
  }

	toggleInfoPago = (referencia) => {
		this.setState((prevState) => ({
			toggle_info_pago: !prevState.toggle_info_pago,
      referenciaSeleccionada: referencia,
    }));
	};

  render() {
    return (
      <React.Fragment>
        <div className="overflow-auto tm-table">
          {
            this.props.change_table === "0" ?
              (<Table size="sm" id="tabla_historico" bordered hover responsive>
                <thead className="text-center header-table">
                  <tr>
                    <th>Folio</th>
                    <th>Aduana</th>
                    <th>Patente</th>
                    <th>Cliente</th>
                    <th>Guia Principal</th>
                    <th>Fecha</th>
                    <th>Observaciones</th>
                    <th>Usuario</th>
                  </tr>
                </thead>
                <tbody className="body-table">
                  {this.state.pagination_alerta.results.map((ale, index, arreglo) => {
										let alerta = "";
                    if (index !== 0)
                      alerta = arreglo[index - 1].folio;
                    return (<tr>
                      {/*
											{alerta === ale.folio ? null :
                        (<td rowSpan={utility.repeticion_alerta(ale.folio, this.state.pagination_alerta.results)} >
                          <p className="text-center mb-0">{ale.folio}</p>
                        </td>)
                      }
											*/}
											<td ><p className="text-center mb-0">{ale.folio}</p></td>
                      <td ><p className="text-center mb-0">{ale.aduana}</p></td>
                      <td ><p className="text-center mb-0">{ale.patente}</p></td>
                      <td ><p className="text-center mb-0">{ale.cliente}</p></td>
                      <td ><p className="text-center mb-0">{ale.guia_principal}</p></td>
                      <td ><p className="text-center mb-0">{ale.fecha_modificacion}</p></td>
                      <td ><p className="text-center mb-0">{ale.observaciones}</p></td>
                      <td ><p className="text-center mb-0">{ale.usuario}</p></td>
                    </tr>
                    )
                  })}
                </tbody>
              </Table>) :
            // * Unused
            // this.props.change_table === "1" ?
            //   (<Table size="sm" id="tabla_historico" bordered hover responsive>
            //     {/* tabla de pedimentos */}
            //   </Table>) :
            // *=============================================
            this.props.change_table === "2" ?
              (<Table size="sm" id="tabla_historico" bordered hover responsive>
                <thead className="text-center header-table">
                  <tr>
                    <th>Folio</th>
                    <th>Cliente</th>
                    <th>Referencia</th>
                    <th>Guía Principal</th>
                    <th>Consolidadora</th>
                    <th>Tipo</th>
                    <th>Patente</th>
                    <th>Proveedor</th>
                    <th>Procedencia</th>
                    <th>Fecha Alta</th>
                    <th>Fecha Llegada</th>
                    <th>Descripción</th>
                    <th>Estado</th>
                    <th>Fecha Modificación</th>
                    <th>Observaciones</th>
                    <th>Usuario</th>
                  </tr>
                </thead>
                <tbody className="body-table">
                  {this.state.pagination_operaciones.results.map((ope, index, arreglo) => {
                    let operacion = "";
                    if (index !== 0)
                      operacion = arreglo[index - 1].folio;
										return (
										<tr key={index}>
											{/*
											{operacion === ope.folio ? null : (
												<td rowSpan={utility.repeticion_alerta(ope.folio, this.state.pagination_operaciones.results)}>
													<a
														className="text-center mb-0"
														onClick={() => this.toggleInfoPago(ope.referencia)}
													>
														{ope.folio}
													</a>
												</td>
											)}
											*/}
											<td>
												<a
													className="text-center mb-0"
													onClick={() => this.toggleInfoPago(ope.referencia)}
												>
													{ope.folio}
												</a>
											</td>
											<td>
												<p className="text-center mb-0">
												{this.getClienteName(ope.cliente)}
												</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.referencia}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.guia_principal}</p>
											</td>
											<td>
												<p className="text-center mb-0">
													{this.getConsolidadoraName(ope.consolidadora)}
												</p>
											</td>
											<td>
												<p className="text-center mb-0">
													{ope.tipo === "0" ? "Importación" : "Exportación"}
												</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.patente}</p>
											</td>
											<td>
												<p className="text-center mb-0">
													{this.getProveedorName(ope.proveedor)}
												</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.procedencia}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.fecha_alta}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.fecha_llegada}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.descripcion}</p>
											</td>
											<td>
												<p className="text-center mb-0">
													{this.StatusName(ope.status)}
												</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.fecha_modificacion}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.observaciones}</p>
											</td>
											<td>
												<p className="text-center mb-0">{ope.usuario}</p>
											</td>
										</tr>
										);
                  })}
                </tbody>
              </Table>) :
              (<Alert color="danger" className="mt-5 col-lg-8 col-12 mx-auto">
                <h2 className="mb-0 text-center">Debe seleccionar una aplicación para ver su tabla de histórico</h2>
              </Alert>)
          }
        </div>
        {this.props.change_table === 0 ? (null) : (
          <Paginacion
						actual={this.state.page_actual}
						count={this.handleTipo_count(this.props.change_table)}
						paginate={this.handlePage}
					/>
        )}
				{this.state.toggle_info_pago ?
					<>
						<ModalOperacion
							toggleModal = {this.state.toggle_info_pago}
							functionToggleInfo={this.toggleInfoPago}
							referencia={this.state.referenciaSeleccionada}
							phxcc={this.props.phxcc}
							beneficiario={this.props.beneficiario}
						/>
					</>
					: null
				}
      </React.Fragment>);
  }
}
export default TableHistoricoOpe;