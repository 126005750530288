/*
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente general de la aplicación anticipos.
*/
import React, { Component } from 'react';
import SearchBar from './SearchBar';
import TableProv from './Table';

//! Pendiente de revisar por usencia de permisos "Proveedores de clientes".

class Proveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualizar: 0,
        };
    }
    update = (cambio) => {
        this.setState({ actualizar: cambio });
    }
    render() {
        return (
            <div className="container-fluid">
                <SearchBar update={this.update} />
                <TableProv update={this.update} actualizar={this.state.actualizar} />
            </div>
        );
    }
}
export default Proveedores;