import axios from "axios";
import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import "../../assets/style.css";
import * as message from "./messages/messages";
import Paginacion from "../Pagination";
import ConsultaEspecifica from "./SearchSpecific";
import * as utility from "./utils/utility";

class TableBeneficiarios extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page_actual: 1,
      pagination: { count: 1, results: [] },
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_pagination_beneficiarios, "pagination");
  }
  componentDidUpdate(prevProps) {
    if (this.props.actualizar !== prevProps.actualizar) {
      this.handlePage(this.state.page_actual);
      this.props.update(0);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handlePage = (page_actual) => {
    this.setState({ page_actual }, () => {
      this.methodGET_API(utility.url_pagination_beneficiarios, "pagination");
    });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    if (data.banco === '') {
        delete data.banco;
      }
    if (state === "pagination") url_intern += `?page=${this.state.page_actual}`;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "pagination":
              if (this._isMounted === true)
                this.setState({
                  pagination: {
                    count: response.data.count,
                    results: response.data.results,
                  },
                });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  onBackTable = () => {
    this.methodGET_API(utility.url_pagination_beneficiarios, "pagination");
  };
  onChangeFiltrada = (data) => {
    let bancoNumero = "";
    if (data.banco) {
      // Extraer el número del banco
      bancoNumero = data.banco.split(" ")[0];
    }

    // Crear un nuevo objeto con los datos modificados
    const newData = {
      nombre: data.nombre,
      banco: bancoNumero,
      cta_banco: data.cta_banco,
    };

    this.setState({ page_actual: 1 }, () => {
      this.methodPOST_API(
        utility.url_pagination_beneficiarios,
        "pagination",
        newData
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        <ConsultaEspecifica
          onBackTable={this.onBackTable}
          onChangeFiltrada={this.onChangeFiltrada}
          bancos={this.props.bancos}
        />
        <div className="overflow-auto tm-table">
          <Table size="sm" id="tabla_consolidadoras" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <th>Número</th>
                <th>Nombre</th>
                <th>Banco</th>
                <th>Cuenta Banco</th>
              </tr>
            </thead>
            <tbody className="body-table hover-gray">
              {this.state.pagination.results.length === 0 ? (
                <tr>
                  <td colSpan="12" align="center">
                    <Spinner
                      style={{ width: "10rem", height: "10rem" }}
                      color="danger"
                      type="grow"
                      className="align-self-center"
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {this.state.pagination.results.map((cliente) => (
                    <tr>
                      <td>
                        <p className="mb-0 text-center">{cliente.id}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{cliente.nombre}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{cliente.banco}</p>
                      </td>
                      <td>
                        <p className="mb-0 text-center">{cliente.cta_banco}</p>
                      </td>
                      {/* <td><p className="mb-0 text-center">{cliente.status}</p></td> */}
                    </tr>
                  ))}
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </div>
        <Paginacion
          actual={this.state.page_actual}
          count={this.state.pagination.count}
          paginate={this.handlePage}
        />
      </React.Fragment>
    );
  }
}
export default TableBeneficiarios;
