/* 
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la consulta de un proveedor. 
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import MyInput from "../../../utils/custom/MyInput";
import WebSocketInstance from "../../../utils/websocket";
import * as message from "../messages/messages";
import * as utility from "../utils/utility";
import CustomSelect from "../../../utils/custom/CustomSelect";

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      paises: [],
      estados: [],

      pais_text: "Seleccione un país",
      pais_id: "",

      identificativo: "",
    };
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "proveedor",
      motivo: motivo,
      folio: [folio],
    };
    WebSocketInstance.newNotificacion(notificacionObject);
  };
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_paises, "paises");
    this.methodGET_API(utility.url_estados, "estados");
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleAlta = () => {
    message
      .message_confimacion("¿Desea continuar con la operación alta?", "")
      .then((res) => {
        if (res) {
          let data = {
            nombre: document.getElementById("nombre_proveedor_alta")?.value || "",
            nombre_corto: document.getElementById("nombre_corto_proveedor_alta")
              ?.value || "",
            id_fiscal: document.getElementById("id_fiscal_proveedor_alta")
              ?.value || "",
            rfc: document.getElementById("rfc_proveedor_alta")?.value || "",
            curp: document.getElementById("curp_proveedor_alta")?.value || "",
            calle: document.getElementById("calle_proveedor_alta")?.value || "",
            no_exterior: document.getElementById("no_exterior_proveedor_alta")
              ?.value || "",
            no_interiro: document.getElementById("no_interior_proveedor_alta")
              ?.value || "",
            codigo_postal: document.getElementById("postal_proveedor_alta")
              ?.value || "",
            colonia: document.getElementById("colonia_proveedor_alta")?.value || "",
            estado: document.getElementById("estado_proveedor_alta")?.value || "",
            pais: this.state.pais_id || "",
            rep_legal: document.getElementById("rep_legal_proveedor_alta")
              ?.value || "",
            telefono: document.getElementById("telefono_proveedor_alta")?.value || "",
            cliente: document.getElementById("cliente_proveedor_alta")?.value || "",
            correo: document.getElementById("correo_proveedor_alta")?.value || "",
          };
          if (this.handleValida(data) === 0) return null;
          this.methodPOST_API(
            utility.url_crea_proveedor,
            "alta_proveedor",
            data
          );
        }
      });
  };

  handleValida = (data) => {
    let message = "";
    if (data.nombre.length < 1) message += "Nombre, ";
    if (data.nombre_corto.length < 1) message += "Nombre corto, ";
    if (data.pais.length < 1) message += "País, ";
    if (message.length > 0) {
      swal("LLenar los campos: ", message, "warning");
      return 0;
    }
    return 1;
  };
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_proveedor":
              this.setState(
                { identificativo: response.data.results.numero },
                () => {
                  this.sendNotificacionHandler("crear");
                }
              );
              this.props.toogleNuevo();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "paises":
              this.setState({
                paises: response.data.results,
              });
              break;
            case "estados":
              this.setState({
                estados: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handlePais = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let pais_text = e[label];
    let pais_id = e[label_id];
    this.setState({ pais_text, pais_id });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.toogle_nuevo}
        toggle={this.props.toogleNuevo}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleNuevo}>
          Alta Proveedor De Cliente
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                id="nombre_proveedor_alta"
                nameLabel="Nombre de Proveedor"
              />
            </FormGroup>

            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                id="nombre_corto_proveedor_alta"
                nameLabel="Nombre corto"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                id="id_fiscal_proveedor_alta"
                nameLabel="ID Fiscal"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                id="rfc_proveedor_alta"
                nameLabel="RFC"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                id="curp_proveedor_alta"
                nameLabel="CURP"
              />
            </FormGroup>

            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="calle_proveedor_alta"
                nameLabel="Calle"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="no_exterior_proveedor_alta"
                nameLabel="Número Exterior"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="no_interior_proveedor_alta"
                nameLabel="Número Interior"
              />
            </FormGroup>

            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="colonia_proveedor_alta"
                nameLabel="Colonia"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                id="correo_proveedor_alta"
                nameLabel="Correo"
              />
            </FormGroup>

            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="clave"
                id="pais_proveedor_alta"
                nameLabel="País"
                value={this.state.pais_text}
                onChange={this.handlePais}
                options={this.state.paises}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="estado_proveedor_alta"
                nameLabel="Estado"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="postal_proveedor_alta"
                nameLabel="Código Postal"
              />
            </FormGroup>

            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="rep_legal_proveedor_alta"
                nameLabel="Representante Legal"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="telefono_proveedor_alta"
                nameLabel="Telefono"
              />
              <MyInput
                type="text"
                bootstrap="col-lg-4"
                id="cliente_proveedor_alta"
                nameLabel="Cliente"
              />
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta}>
            Aceptar
          </Button>
          <Button color="primary" onClick={this.props.toogleNuevo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;
