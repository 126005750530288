export const url_historico_alerta = '/DjangoWeb/AlertasApp/consulta/paginacion/historico';
// export const url_historico_pedimentos = '';     //* Unused
export const url_historico_operaciones = '/DjangoWeb/OperacionesApp/consulta/operacion/paginacion/historico';

export const url_historico_pagos = '/DjangoWeb/OperacionesApp/consulta/operacion/pagos/historico';
export const url_phxcc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';
export const url_proveedor = '/DjangoWeb/BaseApp/consulta/proveedor/lista';
export const url_consolidadoras = '/DjangoWeb/BaseApp/consulta/consolidadoras/lista';
export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_beneficiarios = '/DjangoWeb/BaseApp/consulta/beneficiarios/lista';

export const Burbuja_Alerta = (arreglo) => {
    var lista = arreglo;
    var n, i, k, aux;
    n = lista.length;
    for (k = 1; k < n; k++) {
        for (i = 0; i < (n - k); i++) {
            if (lista[i].folio > lista[i + 1].folio) {
                aux = lista[i];
                lista[i] = lista[i + 1];
                lista[i + 1] = aux;
            }
        }
    }
    return (lista);
}

/**
 * 
 * @param {number} cliente Número de cliente.
 * @param {array} arreglo Arreglo de anticipos.
 */
 export const repeticion_alerta = (alerta, arreglo) => {
    let contador = 0;
    for (let i = 0; i < arreglo.length; i++)
        if (alerta === arreglo[i].folio)
            contador++;
    return contador;
}

export const general_search = (e) => {
		e.preventDefault();
    let tr = document.getElementById('tabla_historico').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_historico').value.toUpperCase();
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }
}

export const data_search = (e) => {
	e.preventDefault();
	var tabla_anticipo, input_general, tr, dato, input_dato, mostrar;
	tabla_anticipo = document.getElementById("tabla_historico");
	input_general = document.getElementById("busqueda_date_historico");
	input_dato = input_general.value;
	tr = tabla_anticipo.getElementsByTagName("tr");
	for (var i = 0; i < tr.length; i++) {
			mostrar = false;
			dato = tr[i].getElementsByTagName("p");
			for (var j = 0; j < dato.length; j++) {
					if (dato[j].textContent === input_dato) {
							mostrar = true;
							dato[j].style.display = "";
							dato[j].style.background = "rgba(0,123,255,1)";
							dato[j].style.color = "#fff";
					}
					else if (input_dato === "") {
							mostrar = true;
							dato[j].style.display = "";
							dato[j].style.background = "";
							dato[j].style.color = "#000";
					}
					else {
							dato[j].style.background = "";
							dato[j].style.color = "#000";
					}
			}
			if (mostrar === true) {
					tr[0].style.display = "";
					tr[i].style.display = "";
			}
			else {
					tr[0].style.display = "";
					tr[i].style.display = "none";
			}
	}
}