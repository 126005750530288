import React from 'react';
import '../../../assets/style.css'

const MyTextArea = (props) => {
    return (
        <div className={`grupo p-0 ${props.bootstrap}`}>
            <textarea className="input-animated col-12 mb-2"
                id={props.id}
                disabled={props.disabled}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                style={{ resize: "none", height:"100px", ...props.style }}
                placeholder=""
            >
            </textarea>
            <span className="barra"></span>
            <br /><label className="label-animated" htmlFor={props.id}>{props.nameLabel}</label>
        </div>
    );
}
export default MyTextArea;

/* ------------------------------------------------------------------------------------------------
ESTRUCTURA PARA LA LOGICA DEL INPUT TEXTAREA MODIFICADO <MyTextArea  />
    SE NECESITA LO SIGUIENTE:
        -UNA VARIABLE PARA GUARDAR EL CONTENIDO DEL INPUT.
            variable: ""
        -UNA FUNCIÓN DE CAMBIO DE ESTADO.
            handleNombre_funcion = (e) => {
                let variable_texto = e.target.value;
                this.setState({ variable_texto });
            }
    LA ESTRUCTURA DEL INPUT TIENE UNA LOGICA, SUS ETIQUETAS SE EXPLICAN A CONTINUCACIÓN:
    <MyTextArea 
        bootstrap="" -> String para agregar clases de bootstrap.
        nameLabel="" -> Nombre del Input a mostar.
        id=""        -> String quer contine el identificador de HTML.
        onChange={}  -> Evento de cambio en el input.
        value={}     -> Variable que guarda el contenido del input.
    />
------------------------------------------------------------------------------------------------ */