import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import * as utility from "../utils/utility";
import * as message from "../messages/messages";
import axios from "axios";
import swal from "@sweetalert/with-react";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import MySelect from "../../../utils/custom/MySelect";
import CustomSelect from "../../../utils/custom/CustomSelect";

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      numero_beneficiario: "",
      nombre: "",
      cta_banco: "",
      banco: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNumero = (e) => {
    this.setState({ numero_beneficiario: e.target.value });
  };

  handleNombre = (e) => {
    this.setState({ nombre: e.target.value });
  };

  handleCuentaBancaria = (e) => {
    this.setState({ cta_banco: e.target.value });
  };

  handleBanco = (e) => {
    this.setState({ banco: e.target.value });
  };

  handleConsulta = () => {
    const { numero_beneficiario } = this.state;

    if (numero_beneficiario !== "") {
      axios
        .post(utility.url_consulta_beneficiarios, {
          id: numero_beneficiario,
        })
        .then((response) => {
          this.setState({
            nombre: response.data.results.nombre,
            cta_banco: response.data.results.cta_banco,
            banco: response.data.results.banco,
          });
        })
        .catch((error) => {
          if (error.response) {
            message.message_modal(
              error.response.data.title,
              error.response.data.text,
              error.response.status
            );
          }
        });
    } else {
      swal({
        title: "Advertencia",
        text: "Debe tener un número",
        icon: "warning",
      });
    }
  };

  handleModificar = () => {
    const { numero_beneficiario, nombre, cta_banco, banco } = this.state;

    axios
      .post(utility.url_consulta_beneficiarios, {
        id: numero_beneficiario,
      })
      .then((response) => {
        const originalData = response.data.results;

        // Compara los datos originales con los datos actuales
      if (
        originalData.nombre !== nombre ||
        originalData.cta_banco !== cta_banco ||
        originalData.banco !== banco
      ) {
        // Obtiene los primeros números del banco modificado
        const nuevoBanco = banco.split(" ")[0];

        // Crea el objeto con los datos diferentes para enviarlos
        const dataToUpdate = {
          id: numero_beneficiario,
          nombre: originalData.nombre !== nombre ? nombre : undefined,
          cta_banco: originalData.cta_banco !== cta_banco ? cta_banco : undefined,
          banco: originalData.banco !== banco ? nuevoBanco : undefined,
        };

          axios
            .put(utility.url_crear_beneficiario, dataToUpdate)
            .then((response) => {
              swal({
                title: "Beneficiario Modificado",
                text: "El beneficiario fue modificado exitosamente",
                icon: "success",
              });
            })
            .catch((error) => {
              if (error.response) {
                message.message_modal(
                  error.response.data.title,
                  error.response.data.text,
                  error.response.status
                );
              }
            });
        } else {
          swal({
            title: "Advertencia",
            text: "No se realizaron cambios",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };

  handleCerrar = () => {
    this.setState({numero_beneficiario: "", nombre: "", cta_banco: "", banco: ""});
    this.props.toogleConsulta();
  };

  handleClean = () => {
    this.setState({numero_beneficiario: "", nombre: "", cta_banco: "", banco: ""});
  }

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        onClosed={this.handleClean}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.props.toogleConsulta}>
          Consulta De Beneficiarios
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                id="numero_beneficiario_consulta"
                value={this.state.numero_beneficiario}
                onChange={this.handleNumero}
                nameLabel="Número de Beneficiario"
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleConsulta}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Nombre de Beneficiario"
                id="nombre_beneficiario_alta"
                onChange={this.handleNombre}
                value={this.state.nombre}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Cuenta Banco"
                id="cuenta_banco"
                onChange={this.handleCuentaBancaria}
                value={this.state.cta_banco}
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6 col-12"
                nameLabel="Bancos"
                id="bancos_alta"
                onChange={this.handleBanco}
                value={this.state.banco}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleModificar}>
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalConsulta;
