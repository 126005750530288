import axios from 'axios';
import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  Form,
  FormGroup,
  FormText,
  Card,
  Badge,
  Table,
} from 'reactstrap';
import * as message from "../../../utils/messages/message";
import MyInput from '../utils/MyInput';
import MyDropzone from '../utils/MyDropzone';
import MyTextArea from '../utils/MyTextArea';
import swal from "@sweetalert/with-react";
import { faEye, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as utility from "../utils/utility";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar
  </a>
);

class ModalPagoInformacion extends Component {
  // Declaración de estado si el componente está montado o no
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      archivo_select: [],
      comprobante: false,
      factura: false,
    };
  }

  // Función que se ejecuta antes de renderizar el componente
  componentDidMount() {
    const { pago } = this.props;
    this.handleComprobanteExist(pago.id);
    this.checkFilesExistence(pago.id);
    this._isMounted = true;
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.handleCerrar();
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.handleCerrar();
      }
    });
  }

  // Función que se ejecuta al cambiar las propiedades del componente
    // Función de cierra del modal
  handleCerrar = () => {
    this.props.functionToggleInfo();
    this.props.ConsultaRefresh();
  };

  // Función de modificación del beneficiario del pago
  handlModBeneficiario = (e, id) => {
    this.props.ModificarBeneficiario(e, id);
  };

  // Función de modificación de banco del pago
  handleModBanco = (e, id) => {
    this.props.ModificarBanco(e, id);
  };

  // Función de modificación de cuenta de banco del pago
  handleModCtaBanco = (e, id) => {
    this.props.ModificarCtaBanco(e, id);
  };

  // Función de modificación de fecha del pago
  handleModFecha = (e, pago) => {
    this.props.ModificarFecha(e, pago);
  };

  // Función de modificación de importe del pago
  handleModImporte = (e, pago) => {
    this.props.ModificarImporte(e, pago);
  };

  // Función de modificación de factura del pago
  handleModFactura = (e, pago) => {
    this.props.ModificarFactura(e, pago);
  };

  // Función de modificación de observaciones del pago
  handleModObservaciones = (e, pago) => {
    this.props.ModificaObservaciones(e, pago);
  };

  // Función de agregar archivos al pago
  handleArchivo = (file) => {
    const LIMITE_PESO_BYTES = 24 * 1024 * 1024; // 25 MB
    let archivos_arr = this.state.archivo_select;

    const calcularPesoTotalBytes = (archivos) => {
      return archivos.reduce((total, archivo) => total + archivo.size, 0);
    };

    const pesoActualBytes = calcularPesoTotalBytes(archivos_arr);
    const pesoNuevosArchivosBytes = file.size;
    const pesoTotalBytes = pesoActualBytes + pesoNuevosArchivosBytes;

    if (pesoTotalBytes > LIMITE_PESO_BYTES) {
      swal(
        "Advertencia",
        "El tamaño total de los archivos no debe exceder los 25 MB",
        "warning"
      );
      return;
    }

    let archivo = file;
    if (archivo.type === "text/xml") {
      let arch = new FileReader();
      arch.onload = (event) => {
        this.setState({ archivo_select: archivo });
        this.handleXMLS(event.target.result);
      };
      arch.readAsText(archivo);
    }
    archivos_arr.push(file);
    this.setState({ archivo_select: archivos_arr });
  };

  methodPOST_API = (url, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400)
          message.message_modal(
            response.data.title,
            response.data.text,
            response.status
          );
          this.setState({
            estatusPago: "L",
          });
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  handlePagoPendiente = (e) => {
    const formData = new FormData();
    formData.append("id", this.props.pago.id);

    // Verificar si hay archivos seleccionados
    if (
      // this.state.archivosPendiente !== "1" &&
      this.state.archivo_select.length === 0
    ) {
      swal("Favor de agregar un archivo", "", "warning");
      return;
    }

    this.state.archivo_select.forEach((archivo) => {
      formData.append("pdf", archivo);
    });

    this.methodPOST_API(utility.url_liquida_pagos, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  // Función existencia de archivo Comprobante
  handleComprobanteExist = (id) => {
    axios
      .get(`/DjangoWeb/OperacionesApp/consulta/operacion/pagos/comprobante/${id}`)
      .then(() => {
        this.setState({ comprobante: true });
      })
      .catch(() => {
        this.setState({ comprobante: false });
      });
  };

  // Función existencia de archivo Factura
  checkFilesExistence = async (id) => {
    axios
      .get(`/DjangoWeb/FacturasApp/consulta/expedinte/facturas/${id}/1`)
      .then(() => {
        this.setState({ factura: true });
      })
      .catch(() => {
        this.setState({ factura: false });
      });
  };

  cargarArchivoPDF = async (id) => {
    try {
      const response = await axios.get(`${utility.url_factura}/${id}/1`, {
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      if (contentType === "application/pdf") {
        const archivoPDF = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        this.setState({ archivoPDF });
      } else {
        console.error("No se recibió un archivo PDF:", contentType);
      }
    } catch (error) {
      console.error("Error al cargar el archivo PDF:", error);
    }
  };

  cargarArchivoZIP = async (id) => {
    try {
      const response = await axios.get(`${utility.url_desc_factura}/${id}/1`, {
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];

      if (contentType === "application/zip") {
        const archivoZIP = Buffer.from(response.data, "binary").toString(
          "base64"
        );
        this.setState({ archivoZIP });
      } else {
        console.error("No se recibió un archivo ZIP:", contentType);
      }
    } catch (error) {
      console.error("Error al cargar el archivo ZIP:", error);
    }
  };

  // Visualizar archivos
  handleEyeClick = (id) => {
    axios
      .get(
        `/DjangoWeb/OperacionesApp/consulta/operacion/pagos/comprobante/${id}`,
        { responseType: "blob" }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        const validTypes = [
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/png",
        ];

        if (validTypes.includes(contentType)) {
          const file = new Blob([response.data], { type: contentType });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        } else {
          console.error("El tipo de archivo recibido no es válido");
        }
      })
      .catch((error) => {
        if (error.response) {
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
        }
      });
  };
  // Descargar archivos
  handleDescarga = (id) => {
    const url = `/DjangoWeb/OperacionesApp/consulta/operacion/pagos/comprobante/${id}`; // URL del archivo
    const element = document.createElement("a");
    element.href = url;
    element.download = "archivo.pdf"; // Nombre del archivo descargado
    document.body.appendChild(element); // Requerido para Firefox
    element.click();
    document.body.removeChild(element);
  };
  // Eliminar archivos ya subidos
  handleEliminaComprobante = async (id) => {
    axios
      .post(`/DjangoWeb/OperacionesApp/elimina/operacion/pagos/comprobante/`, {
        id:id,
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
    this.setState({ comprobante: false });
  };

  // Función de eliminación de archivos del pago durante la carga
  handleEliminaArchivo = (e) => {
    let archivos_arr = this.state.archivo_select;
    archivos_arr.splice(parseInt(e.target.id), 1);
    this.setState({ archivo_select: archivos_arr });
  };

    // Función de importe del pago para eliminarlo
  handleEliminar = (e) => {
    this.props.Eliminar(e);
    this.handleCerrar();
  };

  // Función de guardado de cambios en el pago
  handleModificaciones = () => {
    this.props.GuardarCambiosPago();
  };

  // Renderización de la vista
  render() {
    const { pago } = this.props;

    if ( pago.status === 'A' ||  pago.status === 'P') {
      return (
        <Modal
          isOpen={this.props.toggleModal}
          toggle={this.handleCerrar}
          size="lg"
          backdrop="static"
        >
        <ModalHeader toggle={this.handleCerrar}>
          Información del pago
        </ModalHeader>

        <ModalBody>
          <Form>
            <Card>
              <CardBody>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <FormText color="muted"> Estatus del pago: {pago.status === 'A'? 'Alta' : 'Pendiente'} </FormText>
                </FormGroup>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id={`${pago.id}_beneficiario`}
                      onChange={(e) => this.handlModBeneficiario(e, pago.beneficiario.id)}
                      defaultValue={pago.beneficiario.nombre}
                      nameLabel="Beneficiario"
                      disabled={pago.status === 'A' ? false : true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_metodo_pago`}
                    value={pago.metodo_pago === 'T' ? 'Transferencia' : 'Efectivo'}
                    nameLabel="Método de pago"
                    disabled={true}
                  />
                </FormGroup>
                {pago.metodo_pago === 'T' ? (
                  <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id={`${pago.id}_banco`}
                      defaultValue={pago.beneficiario.banco}
                      onChange={(e) => this.handleModBanco(e, pago.beneficiario.id)}
                      nameLabel="Banco"
                      disabled={pago.status === 'A' ? false : true}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="cuenta_banco"
                      defaultValue={pago.beneficiario.cta_banco}
                      onChange={(e) => this.handleModCtaBanco(e, pago.beneficiario.id)}
                      nameLabel="Cuenta de banco"
                      disabled={pago.status === 'A' ? false : true}
                    />
                  </FormGroup>
                ) : null}
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                    type="date"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_fecha`}
                    defaultValue={pago.fecha}
                    onChange={(e) => this.handleModFecha(e, pago)}
                    nameLabel="Fecha"
                    disabled={pago.status === 'A' ? false : true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_cantidad`}
                    defaultValue={pago.cantidad}
                    onChange={(e) => this.handleModImporte(e, pago)}
                    nameLabel="Importe"
                    disabled={pago.status === 'A' ? false : true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_factura`}
                    defaultValue={pago.factura}
                    onChange={(e) => this.handleModFactura(e, pago)}
                    nameLabel="Factura"
                    disabled={pago.status === 'A' ? false : true}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyTextArea
                    bootstrap="col-12"
                    id="observaciones_operacion"
                    onChange={(e) => this.handleModObservaciones(e, pago)}
                    defaultValue={pago.observaciones}
                    nameLabel="Observaciones"
                    disabled={pago.status === 'A' ? false : true}
                  />
                </FormGroup>
                {/* CARGA DE ARCHIVOS */}
                {pago.status === 'P' ? (
                  <FormGroup className="row mb-0 mt-2 mt-lg-0">
                    <MyDropzone
                      handleArchivo={this.handleArchivo}
                      accept="image/*, .pdf, .jpg, .jpeg, .png"
                    />
                    <div className="col-12 my-2 d-flex justify-content-center flex-wrap">
                      {this.state.archivo_select.map((archivo, index) => (
                        <Badge color="light" className="border">
                          {archivo.name}&nbsp;
                          <span
                            style={{ cursor: "pointer" }}
                            id={index}
                            onClick={this.handleEliminaArchivo}
                          >
                            &times;
                          </span>
                        </Badge>
                      ))}
                    </div>
                    <Button
                      color="primary"
                      className="col-12"
                      onClick={this.handlePagoPendiente}
                    >
                      Subir archivos
                    </Button>
                  </FormGroup>
                ) : null}
              </CardBody>
            </Card>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            data-id={pago.id}
            onClick={this.handleEliminar}
          >
            Eliminar
          </Button>
          {pago.status === 'A' ? (
            <Button
            color="info"
            onClick={this.handleModificaciones}
            >
              Modificar Pago
            </Button>
          ) : null}
          <Button
            color="primary"
            onClick={this.handleCerrar}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      );
    } else if (pago.status === 'C' || pago.status === 'L') {    // Estado del pago: Comprobado o Liquidado
      return (
        <Modal
          isOpen={this.props.toggleModal}
          toggle={this.handleCerrar}
          size="lg"
          backdrop="static"
        >
        <ModalHeader toggle={this.handleCerrar}>
          Información del Pago
        </ModalHeader>

        <ModalBody>
          <Form>
            <Card>
              <CardBody>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <FormText color="muted"> Estatus del pago: {pago.status === 'C'? 'Comprobado' : 'Liquidado'} </FormText>
                </FormGroup>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id={`${pago.id}_beneficiario`}
                      value={pago.beneficiario.nombre}
                      nameLabel="Beneficiario"
                      disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_metodo_pago`}
                    value={pago.metodo_pago === 'T' ? 'Transferencia' : 'Efectivo'}
                    nameLabel="Método de pago"
                    disabled={true}
                  />
                </FormGroup>
                {pago.metodo_pago === 'T' ? (
                  <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id={`${pago.id}_banco`}
                      value={pago.beneficiario.banco}
                      nameLabel="Banco"
                      disabled={true}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="cuenta_banco"
                      value={pago.beneficiario.cta_banco}
                      nameLabel="Cuenta de banco"
                      disabled={true}
                    />
                  </FormGroup>
                ) : null}
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                    type="date"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_fecha`}
                    defaultValue={pago.fecha}
                    onChange={(e) => this.handleModFecha(e, pago)}
                    nameLabel="Fecha"
                    disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_cantidad`}
                    defaultValue={pago.cantidad}
                    onChange={(e) => this.handleModImporte(e, pago)}
                    nameLabel="Importe"
                    disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_factura`}
                    defaultValue={pago.factura}
                    onChange={(e) => this.handleModFactura(e, pago)}
                    nameLabel="Factura"
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyTextArea
                    bootstrap="col-12"
                    id="observaciones_operacion"
                    value={pago.observaciones}
                    nameLabel="Observaciones"
                    disabled={true}
                  />
                </FormGroup>
                {/* Seccion de visualización de archivos */}
                <Table size="sm" className="mt-2" bordered hover responsive>
                  <thead className="text-center header-table">
                    <tr>
                      <td>Tipo de Archivo</td>
                      <td>Descarga</td>
                      <td>Visualiza</td>
                      <td>Elimina</td>
                    </tr>
                  </thead>
                  <tbody className="body-table">
                    <tr>
                      <td className="text-center">Comprobante</td>
                      {this.state.comprobante ? (
                        <React.Fragment>
                          <td className="text-center">
                            <LinkD
                              href="#"
                              onClick={(e) => {e.preventDefault(); this.handleDescarga(pago.id);}}
                              target="_blank"
                            />
                          </td>
                          <td className="text-center">
                            <LinkV
                              href="#"
                              onClick={(e) => {e.preventDefault(); this.handleEyeClick(pago.id);}}
                              target="_blank"
                            />
                          </td>
                          <td className="text-center">
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => this.handleEliminaComprobante(pago.id)}
                              disabled={this.state.factura ? true : false}
                            >
                              Eliminar
                            </Button>
                          </td>
                        </React.Fragment>
                      ) : (
                        <td
                          className="text-center"
                          colSpan={3}
                        >
                          No existe registro del archivo
                        </td>
                      )}
                    </tr>
                    { pago.status === 'C' ? (
                      <>
                        <tr>
                          <td className="text-center">Factura</td>
                          {this.state.factura ? (
                            <>
                              <td className="text-center">
                                <LinkD
                                  href={`DjangoWeb/FacturasApp/descarga/expedinte/facturas/${pago.id}/1`}
                                />
                              </td>
                              <td className="text-center">
                                <LinkV
                                  href={`DjangoWeb/FacturasApp/consulta/expedinte/facturas/${pago.id}/1`}
                                  id={`dowload_pdf_${pago.id}`}
                                  target="_blank"
                                />
                              </td>
                              <td className="text-center">
                                <Button
                                  color="danger"
                                  size="sm"
                                  disabled={true}
                                >
                                  Eliminar
                                </Button>
                              </td>
                            </>
                          ) : (
                            <td
                              className="text-center"
                              colSpan={3}
                            >
                              No existe registro del archivo
                            </td>
                          )}
                        </tr>
                      </>
                    ) : null }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            data-id={pago.id}
            onClick={this.handleEliminar}
            disabled={pago.status === 'C'}
          >
            Eliminar
          </Button>
          <Button
            color="primary"
            onClick={this.handleCerrar}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      );
    } else {    // Estado del pago: Cancelado
      return (
        <Modal
          isOpen={this.props.toggleModal}
          toggle={this.handleCerrar}
          size="lg"
          backdrop="static"
        >
        <ModalHeader toggle={this.handleCerrar}>
          Información del Pago
        </ModalHeader>

        <ModalBody>
          <Form>
            <Card>
              <CardBody>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <FormText color="muted"> Estatus del pago: Cancelado </FormText>
                </FormGroup>
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                      type="text"
                      bootstrap="col-lg-8 col-12"
                      id={`${pago.id}_beneficiario`}
                      value={pago.beneficiario.nombre}
                      onBlur={this.handleImporteBlur}
                      onChange={this.handleImporte}
                      nameLabel="Beneficiario"
                      disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_metodo_pago`}
                    value={pago.metodo_pago === 'T' ? 'Transferencia' : 'Efectivo'}
                    nameLabel="Método de pago"
                    disabled={true}
                  />
                </FormGroup>
                {pago.metodo_pago === 'T' ? (
                  <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id={`${pago.id}_banco`}
                      value={pago.beneficiario.banco}
                      nameLabel="Banco"
                      disabled={true}
                    />
                    <MyInput
                      type="text"
                      bootstrap="col-lg-6 col-12"
                      id="cuenta_banco"
                      value={pago.beneficiario.cta_banco}
                      nameLabel="Cuenta de banco"
                      disabled={true}
                    />
                  </FormGroup>
                ) : null}
                <FormGroup className="row mb-1 d-flex justify-content-center general_sub">
                  <MyInput
                    type="date"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_fecha`}
                    defaultValue={pago.fecha}
                    onChange={(e) => this.handleModFecha(e, pago)}
                    nameLabel="Fecha"
                    disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_cantidad`}
                    defaultValue={pago.cantidad}
                    onChange={(e) => this.handleModImporte(e, pago)}
                    nameLabel="Importe"
                    disabled={true}
                  />
                  <MyInput
                    type="text"
                    bootstrap="col-lg-4 col-12"
                    id={`${pago.id}_factura`}
                    defaultValue={pago.factura}
                    onChange={(e) => this.handleModFactura(e, pago)}
                    nameLabel="Factura"
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup className="row mb-0">
                  <MyTextArea
                    bootstrap="col-12"
                    id="observaciones_operacion"
                    value={pago.observaciones}
                    nameLabel="Observaciones"
                    disabled={true}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            data-id={pago.id}
            onClick={this.handleCerrar}
          >
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      );
    }
  }
}

export default ModalPagoInformacion;