/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
import "../../assets/style.css";
import * as permisos from "../../utils/permisos/permisos";
import * as utility from "./utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";

class BusquedaTabla extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleTable = (e) => {
    let num_table = e.target.value;
    this.props.changeTable(num_table);
  };

  render() {
    return (
      <div className="row my-2 centered">
        <div className="col-lg-4 col-md-8 col-sm-12 my-2 centered rounded-box">
          <h3 className="col-md-11 col-9 text-md-center mb-0">
            <FontAwesomeIcon icon={ faCalculator } /> Histórico
          </h3>
        </div>
        <div className="col-lg-9 col-sm-12 my-2">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="d-flex justify-content-center flex-column mb-0">
              <Input
                type="text"
                placeholder="Buscar"
                id="busqueda_general_sol_anticipos"
                onKeyUp={utility.general_search}
              />
              <Input
                type="date"
                id="busqueda_date_sol_antcipos"
                className="mt-1"
                onChange={utility.date_search}
              />
            </FormGroup>
          </Form>
        </div>
        <div
          className="col-lg-3 col-sm-12 mb-2 my-md-2 rounded overflow-auto"
          style={{ height: "80px" }}
        >
          <Button
            color="primary"
            className="col-12 rounded-0"
            value="0"
            onClick={this.handleTable}
            disabled={permisos.obtener_permiso_especifico(
              "visualizar",
              this.props.permisos.poliza
            )}
          >
            Polizas
          </Button>
        </div>
      </div>
    );
  }
}
export default BusquedaTabla;
