/* 
Autor: Alexis Yael Hernández Grimaldo
Descripción: El componenete genera un bloque correspondiente a la busqueda dinamica en la tabla para acceder a la información.
*/
import React, { Component } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import * as utility from './utils/utility';
import ModalAction from './Modal/ModalAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';

class BusquedaTabla extends Component {
    render() {
        return (
            <div className="row my-2 centered">
                <div className="col-lg-4 col-md-8 col-sm-12 my-2 centered rounded-box">
                    <h3 className="col-md-11 col-9 text-md-center mb-0">
                        <FontAwesomeIcon icon={ faToolbox } /> Puestos
                    </h3>
                </div>
                <div className="col-lg-9 col-sm-12 my-2">
                    <Form onSubmit={e => { e.preventDefault(); }}>
                        <FormGroup className="d-flex justify-content-center flex-column mb-0">
                            <Input type="text" placeholder="Buscar" id="busqueda_general_puestos" onKeyUp={utility.general_search} />
                        </FormGroup>
                    </Form>
                </div>
                <ModalAction update={this.props.update} permisos={this.props.permisos} />
            </div>
        );
    }
}
export default BusquedaTabla;