export const url_clientes = '/DjangoWeb/BaseApp/consulta/clientes/lista';
export const url_referencias = '/DjangoWeb/BaseApp/consulta/operacion/referencias_pendientes';
export const url_consulta_referencia = '/DjangoWeb/BaseApp/consulta/operacion/referencia';
export const url_bancos = '/DjangoWeb/BaseApp/consulta/bancos/lista';
export const url_contribuciones = '/DjangoWeb/BaseApp/consulta/contribuciones/lista';

export const url_ingresos = '/DjangoWeb/BaseApp/consulta/cat_ingresos/lista';
export const url_phxcc = '/DjangoWeb/BaseApp/consulta/cat_phxcdc/lista';
export const url_patentes = '/DjangoWeb/BaseApp/consulta/agente_aduanal/lista';

export const url_paginacion = '/DjangoWeb/SolicitudAnticipoApp/consulta/solicitud_anticipo/paginacion/principal'; 
export const url_consulta_solicitud = '/DjangoWeb/SolicitudAnticipoApp/consulta/solicitud_anticipo'; 
export const url_pdf_solicitud = '/DjangoWeb/SolicitudAnticipoApp/consulta/solicitud_anticipo/pdf/';
export const url_crear_modifica_solicitud = '/DjangoWeb/SolicitudAnticipoApp/crea_modifica/solicitud_anticipo'; 
export const url_crea_elimina_movimiento = '/DjangoWeb/SolicitudAnticipoApp/crea_elimina/solicitud_anticipo/movimiento'; 
export const url_cancelar_solicitud = '/DjangoWeb/SolicitudAnticipoApp/cancela/solicitud_anticipo';

export const url_verify = '/DjangoWeb/UsuariosApp/verica/usuario';

export const url_consulta_orden = '/DjangoWeb/NotificacionesDepositoApp/consulta/notificacion_deposito/paginacion/principal';
export const url_crea_modifica_orden = '/DjangoWeb/NotificacionesDepositoApp/crea_modifica/notificacion_deposito';
export const url_notificar_orden = '/DjangoWeb/NotificacionesDepositoApp/notifica/notificacion_deposito';
export const url_cancelar_orden = '/DjangoWeb/NotificacionesDepositoApp/cancela/notificacion_depositos';
export const url_consulta_orden_especifica = '/DjangoWeb/NotificacionesDepositoApp/consulta/notificacion_deposito';

export const url_consulta_sin_referencia = '/DjangoWeb/SolicitudAnticipoApp/consulta/presupuesto_deposito';

export const referencia_filtrada = '/DjangoWeb/BaseApp/consulta/operacion/referencia_filtrada';

/**
 * Función que permite obtener el nivel que tendra un usuario en la aplicación dada.
 * @param {Array} apps Arreglo de apps que puede ver el usuario.
 * @param {String} actual_app Numero de app a buscar su nivel. 
 * @returns {Number} Nivel de la app.
 */
export const get_nivel_app = (apps, actual_app) => {
    for (let i = 0; i < apps.length; i++)
        if (apps[i].app === actual_app)
            return apps[i].nivel;
}

/**
 * Función que retorna el tipo de solicitud que es.
 * @param {number} type_sol 
 * @returns {String} Tipo de solicitud.
 */
export const tipo_solicitud = (type_sol) => {
    switch (type_sol) {
        case 0: return 'Servicios';
        case 1: return 'Cuadro Liquidación';
        case 2: return 'Pagos a Terceros';
        default: break;
    }
}
/**
 * Función que nos permite filtar la tabla por el input general que serra de tipo text.
 */
export const general_search = (e) => {
    e.preventDefault();
    let tr = document.getElementById('tabla_solicitud_anticipos').getElementsByTagName('tr');
    let trDos = document.getElementById('tabla_solicitud_anticiposDos').getElementsByTagName('tr');
    let data_input = document.getElementById('busqueda_general_sol_anticipos').value.toUpperCase();
    
    for (let i = 0; i < tr.length; i++) {
        let mostrar = false;
        let data = tr[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }

    for (let i = 0; i < trDos.length; i++) {
        let mostrar = false;
        let data = trDos[i].getElementsByTagName('p');
        for (let j = 0; j < data.length; j++) {
            if (data_input === "") {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
            else if (data[j].textContent.toUpperCase().includes(data_input)) {
                mostrar = true;
                data[j].style.display = "";
                data[j].style.background = "rgba(0,123,255,1)";
                data[j].style.color = "#fff";
            }
            else {
                data[j].style.background = "";
                data[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            trDos[0].style.display = "";
            trDos[i].style.display = "";
        }
        else {
            trDos[0].style.display = "";
            trDos[i].style.display = "none";
        }
    }
}
/**
 * Función que nos permite filtrar latabla solamnete por la fecha.
 */
export const date_search = (e) => {
    e.preventDefault();
    var tabla_anticipo, input_general, tr, dato, trDos, input_dato, mostrar,tabla_anticipoDos;
    tabla_anticipo = document.getElementById("tabla_solicitud_anticipos");
    tabla_anticipoDos = document.getElementById("tabla_solicitud_anticiposDos");
    input_general = document.getElementById("busqueda_date_sol_antcipos");
    input_dato = input_general.value;
    tr = tabla_anticipo.getElementsByTagName("tr");
    trDos = tabla_anticipoDos.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
        mostrar = false;
        dato = tr[i].getElementsByTagName("p");
        for (var j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            tr[0].style.display = "";
            tr[i].style.display = "";
        }
        else {
            tr[0].style.display = "";
            tr[i].style.display = "none";
        }
    }

    for (i = 0; i < trDos.length; i++) {
        mostrar = false;
        dato = trDos[i].getElementsByTagName("p");
        for (j = 0; j < dato.length; j++) {
            if (dato[j].textContent === input_dato) {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "rgba(0,123,255,1)";
                dato[j].style.color = "#fff";
            }
            else if (input_dato === "") {
                mostrar = true;
                dato[j].style.display = "";
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
            else {
                dato[j].style.background = "";
                dato[j].style.color = "#000";
            }
        }
        if (mostrar === true) {
            trDos[0].style.display = "";
            trDos[i].style.display = "";
        }
        else {
            trDos[0].style.display = "";
            trDos[i].style.display = "none";
        }
    }
}
/**
 * Función que obtiene los nombres de todos los clientes
 * @param {array} arreglo 
 * @returns {array} Arreglo de nombres.
 */
export const get_nombres_clientes = (arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        aux.push(arreglo[i].nombre);
    }
    return (aux);
}
/**
 * Función que permite retornar el nombre de un cliente ne especifico.
 * @param {String} numero Número de cliente.
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Nombre del cliente.
 */
export const get_name_cliente = (numero, array) => {
    let complete_number = "";
    switch (numero.length) {
        case 1: complete_number = '000' + numero; break;
        case 2: complete_number = '00' + numero; break;
        case 3: complete_number = '0' + numero; break;
        case 4: complete_number = numero; break;
        default: break;
    }
    for (let i = 0; i < array.length; i++)
        if (complete_number === array[i].numero)
            return (array[i].nombre);
}
/**
 * Finción que permite el retorno del número de cliente por su nombre.
 * @param {Sring} nombre Nombre del cliente. 
 * @param {Array} array Arreglo de clientes.
 * @returns {String} Número del cliente.
 */
export const get_number_cliente = (nombre, array) => {
    let only_number = "";
    let bandera = 0;
    for (let i = 0; i < array.length; i++)
        if (nombre === array[i].nombre) {
            for (let j = 0; j < 4; j++) {
                if (array[i].numero[j] !== '0')
                    bandera = 1;
                if (bandera === 1)
                    only_number += array[i].numero[j];
            }
            return (only_number);
        }
}
/**
 * Anexa los 0 faltantes del número del cliente
 * @param {String} number Número del cliente
 */
export const complete_number_cliente = (number) => {
    let text_add = "";
    switch (number.length) {
        case 1:
            text_add = "000";
            text_add += number;
            break;
        case 2:
            text_add = "00";
            text_add += number;
            break;
        case 3:
            text_add = "0";
            text_add += number;
            break;
        case 4:
            text_add += number;
            break;
        default:
            return null;
    }
    return text_add;
}
export const get_referencias_cliente = (cliente, arreglo) => {
    var aux = [];
    for (var i = 0; i < arreglo.length; i++) {
        if (arreglo[i].cliente === complete_number_cliente(cliente))
            aux.push(arreglo[i].referencia);
    }
    return (aux);
}
export const get_aduana = (referencia, cliente, array) => {
    let first_filter = [];
    for (let i = 0; i < array.length; i++)
        if (array[i].cliente === cliente)
            first_filter.push(array[i]);
    for (let j = 0; j < first_filter.length; j++)
        if (first_filter[j].referencia === referencia) {
            return first_filter[j].aduana;
        }
}
/**
 * Función que genera la fecha actual del día y su hora axacta.
 * @returns {Object} Contiene fecha y hora.
 */
export const get_date_actual = () => {
    const actual = new Date();
    return {
        fecha: actual.toISOString().slice(0, 10),
        hora: actual.toISOString().slice(11, 16)
    }
}
export const get_data_solicitud_anticipo = () => {
    const solicitud_anticipo = {
        aduana: document.getElementById('aduana_sol_anticipo').value,
        referencia: document.getElementById('referencia_sol_anticipo').value,
        observaciones: document.getElementById('observacion_sol_anticipo').value,
    }
    return solicitud_anticipo;
}
export const get_solicitud_anticipo_sin_referencia = () => {
    const solicitud_anticipo = {
        aduana: document.getElementById('aduana_sol_anticipo').value,
        observaciones: document.getElementById('observacion_sol_anticipo').value,
    }
    return solicitud_anticipo;
}
export const get_data_movimiento = () => {
    const movimiento = {
        id_solicitud: document.getElementById('numero_solicitud_anticipo_mov').value,
    }
    return movimiento;
}
export const get_type_concepto = (type_concepto, type_mov, arr_ing, arr_pag, arr_ter) => {
    if (type_mov === "0")
        for (let i = 0; i < arr_ing.length; i++)
            if (parseInt(type_concepto) === arr_ing[i].clave) {
                return (arr_ing[i].corto);
            }
    if (type_mov === "1")
        for (let i = 0; i < arr_pag.length; i++)
            if (parseInt(type_concepto) === arr_pag[i].id) {
                return (arr_pag[i].corto);
            }
    if (type_mov === "2")
        for (let i = 0; i < arr_ter.length; i++)
            if (parseInt(type_concepto) === arr_ter[i].clave) {
                return (arr_ter[i].corto);
            }
}

export const get_type_conceptoDos = (type_concepto, type_mov, arr_ing, arr_pag, arr_ter) => {
    const parsedTypeMov = parseInt(type_mov);
  
    if (parsedTypeMov === 0) {
      for (let i = 0; i < arr_ing.length; i++) {
        if (parseInt(type_concepto) === arr_ing[i].clave) {
          return arr_ing[i].corto;
        }
      }
    } else if (parsedTypeMov === 1) {
      for (let i = 0; i < arr_pag.length; i++) {
        if (parseInt(type_concepto) === arr_pag[i].id) {
          return arr_pag[i].corto;
        }
      }
    } else if (parsedTypeMov === 2) {
      for (let i = 0; i < arr_ter.length; i++) {
        if (parseInt(type_concepto) === arr_ter[i].clave) {
          return arr_ter[i].corto;
        }
      }
    }
  
    return "";
  };

  
export const get_type_concepto_tabla = (type_concepto, type_mov, arr_ing, arr_pag, arr_ter) => {
    if (type_mov === 0)
        for (let i = 0; i < arr_ing.length; i++)
            if (parseInt(type_concepto) === arr_ing[i].clave) {
                return (arr_ing[i].corto);
            }
    if (type_mov === 1)
        for (let i = 0; i < arr_pag.length; i++)
            if (parseInt(type_concepto) === arr_pag[i].id) {
                return (arr_pag[i].corto);
            }
    if (type_mov === 2)
        for (let i = 0; i < arr_ter.length; i++)
            if (parseInt(type_concepto) === arr_ter[i].clave) {
                return (arr_ter[i].corto);
            }
}


export const get_sum_solicitudes = (solicitudes, opion) => {
    let suma = 0;
    switch (opion) {
        case 0:
            for (let i = 0; i < solicitudes.length; i++)
                for (let j = 0; j < solicitudes[i].solicitud_ant[0].movimientos.length; j++)
                    if (solicitudes[i].solicitud_ant[0].movimientos[j].tipo_solicitud === 0)
                        suma += parseFloat(solicitudes[i].solicitud_ant[0].movimientos[j].monto);
            break;
        case 1:
            for (let i = 0; i < solicitudes.length; i++)
                for (let j = 0; j < solicitudes[i].solicitud_ant[0].movimientos.length; j++)
                    if (solicitudes[i].solicitud_ant[0].movimientos[j].tipo_solicitud === 1)
                        suma += parseFloat(solicitudes[i].solicitud_ant[0].movimientos[j].monto);
            break;
        case 2:
            for (let i = 0; i < solicitudes.length; i++)
                for (let j = 0; j < solicitudes[i].solicitud_ant[0].movimientos.length; j++)
                    if (solicitudes[i].solicitud_ant[0].movimientos[j].tipo_solicitud === 2)
                        suma += parseFloat(solicitudes[i].solicitud_ant[0].movimientos[j].monto);
            break;
        case 3:
            for (let i = 0; i < solicitudes.length; i++)
                for (let j = 0; j < solicitudes[i].solicitud_ant[0].movimientos.length; j++)
                    suma += parseFloat(solicitudes[i].solicitud_ant[0].movimientos[j].monto);
            break;
        default:
            break;

    }
    return suma;
}
export const add_class_disabled = (id) => {
    document.getElementById(id).classList.add('input-animatedd');
}

export const valida_data_deposito_anticipo = (data) => {
    let message = "";
    if (data.solicitudes)
        if (data.solicitudes.length === 0)
            message += "Agregar una solicitud,"
    if (data.importe.length === 0)
        message += "Importe, ";
    if (data.cliente.length === 0)
        message += "Cliente, ";
    if (data.observaciones.length === 0)
        message += "Observaciones, ";
    return message;
}