/*
    Autor: Alexis Yael Hernández Grimaldo.
    Descripción: Componente único para el modal, genera la alta de un anticipo.
*/
import swal from "@sweetalert/with-react";
import axios from "axios";
import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import * as utility from "../utils/utility";
import {
  faPlus,
  faFileDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as message from "../messages/messages";
import * as permisos from "../../../utils/permisos/permisos";
import WebSocketInstance from "../../../utils/websocket";
import MyInput from "../../../utils/custom/MyInput";
import MyTextArea from "../../../utils/custom/MyTextArea";
import CustomSelect from "../../../utils/custom/CustomSelect";

const LinkV = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faEye} /> Visualizar PDF
  </a>
);
const LinkD = (props) => (
  <a {...props}>
    <FontAwesomeIcon icon={faFileDownload} /> Descargar PDF
  </a>
);

class ModalConsulta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      num_select: "",
      nom_cliente_select: "",
      num_cliente_select: "",
      aduana_select: "",
      referencia_select: "",
      fecha_select: "",
      importe_select: "",
      observaciones_select: "",
      observaciones_select_mov: "",
      status: "",
      cambio_solicitud: 0,
      movimientos: [],
      movimientos_edit: [],
      arr_ingresos: [],
      arr_pagos: [],
      arr_terceros: [],
      all_referencias: [],
      referencias_suggestions: [],
      movimiento_select_text: "Seleccione un tipo de movimiento",
      movimiento_select_id: "",
      movimiento_sol_anticipo_text: "Seleccione un movimiento",
      movimiento_sol_anticipo_id: "",
      movimientos_agrega: [],
      movimientos_elimina: [],
      ingresos: [],
      contribuciones: [],
      phxcc: [],
      enable_referencia: true,
      enable_observaciones: true,
      enable_new_mov: true,
      button_modificacion: true,
      enable_pdf: true,
      movimientos_new_sol: [],
      importe: "",

      tipos_movimiento: [
        { nombre: "Servicios", clave: "0" },
        { nombre: "Cuadro de Liquidación", clave: "1" },
        { nombre: "Pagos hechos por cuenta del cliente", clave: "2" },
      ],
    };
    this.handleNumber = this.handleNumber.bind(this);
  }

  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.referencia_select;
    const notificacionObject = {
      tipo: 0,
      aplicacion: "solicitud_anticipo",
      motivo: motivo,
      folio: [folio],
    };
    if (this.props.on_cliente === false)
      notificacionObject["cliente"] = [parseInt(this.state.num_cliente_select)];
    WebSocketInstance.newNotificacion(notificacionObject);
  };

  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_ingresos, "ingresos");
    this.methodGET_API(utility.url_contribuciones, "pagos");
    this.methodGET_API(utility.url_phxcc, "tercero");
    document.addEventListener("keyup", this.handleEnterKeyPress);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keyup", this.handleEnterKeyPress);
  }

  handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada fue 'Enter', llamamos a la función para manejar el evento
      this.handleImporteBlur();
    }
  };

  handleImporteBlur = () => {
    let importe = this.state.importe;

    // Si el campo está vacío, establecer el valor como '0.00'
    if (!importe) {
      importe = "0.00";
    } else {
      // Convertir el valor a un número
      let num = parseFloat(importe);

      // Verificar si el valor es un número válido
      if (!isNaN(num)) {
        // Formatear el número con dos decimales
        importe = num.toFixed(2);
      } else {
        // Si no es un número válido, establecer el valor como '0.00'
        importe = "0.00";
      }
    }

    this.setState({ importe: importe });
  };

  handleImporte = (e) => {
    let importe = e.target.value;
    this.setState({ importe });
  };

  handleNumber = (e) => {
    this.setState({
      num_select: e.target.value,
    });
  };

  getAduana = (patente) => {
    const aduana = this.props.patentes.find(
      (item) => item.numero.toString() === patente
    );
    return aduana ? aduana.aduana : null;
  };

  handleSolicitud = (e) => {
    if (this.state.num_select.length === 0)
      message.message_modal(
        "Advertencia",
        "Debe tener un número de anticipo",
        400
      );
    else {
      let number_solicitud = this.state.num_select;
      if (this.props.on_cliente !== false)
        this.methodPOST_API(utility.url_consulta_solicitud, "solicitud", {
          id_solicitud: number_solicitud,
          cliente: this.props.on_cliente,
        });
      else
        this.methodPOST_API(utility.url_consulta_solicitud, "solicitud", {
          id_solicitud: number_solicitud,
        });
    }
  };

  handleReferencia = (e) => {
    this.setState({
      referencia_select: e.target.value,
      cambio_solicitud: 1,
    });
  };

  handleObservacion = (e) => {
    this.setState({
      observaciones_select: e.target.value,
    });
  };

  handleObservacionMov = (e) => {
    this.setState({
      observaciones_select_mov: e.target.value,
    });
  };

  handleModificacion = async () => {
    let campos = "";
    if (this.state.referencia_select.length === 0) campos += "Referencia\n";

    if (campos.length !== 0) {
      message.message_modal(
        "Advertencia",
        `Completar los campos: ${campos}`,
        400
      );
      return;
    }

    const res = await message.message_confimacion_textarea(
      "¿Desea continuar con la operación modificar?",
      "Ingrese sus observaciones "
    );

    if (res !== true) return;

    const observaciones = document.getElementById(
      "observaciones_textarea"
    ).value;
    const {
      movimientos,
      movimientos_edit,
      movimientos_new_sol,
      movimientos_elimina,
      referencia_select,
      cambio_solicitud,
      num_select,
    } = this.state;

    let data_modificacion = {};
    let importe = 0;
    let movimientosFiltrados = movimientos.filter(
      (mov) =>
        !movimientos_edit.some(
          (edit) => mov.clave_concepto === edit.clave_concepto
        )
    );

    movimientosFiltrados.forEach((mov) => (importe += parseFloat(mov.monto)));
    importe = parseFloat(importe.toFixed(2));

    if (cambio_solicitud === 1) {
      data_modificacion["referencia"] = referencia_select;
    }

    if (movimientos_edit.length > 0) {
      movimientos_edit.forEach((mov) => {
        mov.tipo_solicitud = mov.tipo_solicitud.toString();
        mov.clave_concepto = mov.clave_concepto.toString();
        importe += parseFloat(mov.monto);
        importe = parseFloat(importe.toFixed(2));
      });
      data_modificacion["movimientos"] = movimientos_edit;
    }

    if (movimientos_new_sol.length > 0) {
      movimientos_new_sol.forEach((mov) => {
        mov.tipo_solicitud = mov.tipo_solicitud.toString();
        mov.clave_concepto = mov.clave_concepto.toString();
        importe += parseFloat(mov.monto);
        importe = parseFloat(importe.toFixed(2));
      });
      data_modificacion["movimientos_agrega"] = movimientos_new_sol;
    }

    if (movimientos_elimina.length > 0 && importe > 0) {
      movimientos_elimina.forEach((mov) => {
        mov.tipo_solicitud = mov.tipo_solicitud.toString();
        mov.clave_concepto = mov.clave_concepto.toString();
        importe -= parseFloat(mov.monto);
        importe = parseFloat(importe.toFixed(2));
      });
      data_modificacion["movimientos_elimina"] = movimientos_elimina;
    }

    data_modificacion["importe"] = importe.toFixed(2);

    const consumo_POST = () => {
      if (Object.keys(data_modificacion).length > 0) {
        data_modificacion["id_solicitud"] = num_select;
        data_modificacion["observaciones"] = observaciones;
        return axios.put(
          utility.url_crear_modifica_solicitud,
          data_modificacion
        );
      } else {
        message.message_modal("Advertencia", "No hay alguna modificación", 400);
        return Promise.reject(new Error("No hay alguna modificación"));
      }
    };

    this.sendNotificacionHandler("modificar");

    try {
      const post = await consumo_POST();
      this.handleCerrar();
      message.message_modal(post.data.title, post.data.text, post.status);
      this.props.update(1);
    } catch (error) {
      if (error.response) {
        this.handleCerrar();
        message.message_modal(
          error.response.data.title,
          error.response.data.text,
          error.response.status
        );
      } else {
        message.message_modal("Advertencia", error.message, 400);
      }
    }
  };

  handleCerrar = (e) => {
    this.setState({
      num_select: "",
      nom_cliente_select: "",
      num_cliente_select: "",
      aduana_select: "",
      referencia_select: "",
      fecha_select: "",
      importe_select: "",
      observaciones_select: "",
      observaciones_select_mov: "",
      status: "",
      cambio_solicitud: 0,
      movimientos: [],
      movimientos_edit: [],
      all_referencias: [],
      referencias_suggestions: [],
      movimiento_select_text: "Seleccione un tipo de movimiento",
      movimiento_select_id: "",
      movimiento_sol_anticipo_text: "Seleccione un movimiento",
      movimiento_sol_anticipo_id: "",
      movimientos_agrega: [],
      movimientos_elimina: [],
      ingresos: [],
      contribuciones: [],
      phxcc: [],
      enable_referencia: true,
      enable_observaciones: true,
      enable_new_mov: true,
      button_modificacion: true,
      enable_pdf: true,
      movimientos_new_sol: [],
      importe: "",
    });
    this.props.toogleConsulta();
  };

  handleCancelar = (e) => {
    message
      .message_confirmacion_password(
        "¿Desea continuar con la operación cancelar?",
        "Ingrese su contraseña y observaciones"
      )
      .then((res) => {
        if (res === true) {
          let mov = {
            user: document.getElementById("usuario_modificador").value,
            pass: document.getElementById("contraseña_modificador").value,
            motivo: document.getElementById("observaciones_textarea").value,
          };
          if (mov.motivo.length === 0)
            swal("Copletar el campo:", "Motivo de Notificación", "warning");
          else
            axios
              .post(utility.url_verify, {
                usuario: mov.user,
                password: mov.pass,
              })
              .then((response) => {
                if (response) {
                  if (this.state.num_select.length === 0)
                    message.message_modal(
                      "Advertencia",
                      "Debe tener un número de anticipo",
                      400
                    );
                  else {
                    let data = {
                      id_solicitud: this.state.num_select,
                      observaciones: mov.motivo,
                    };
                    this.methodPOST_API(
                      utility.url_cancelar_solicitud,
                      "cancelar",
                      data
                    );
                    this.sendNotificacionHandler("cancelar");
                  }
                }
              })
              .catch((error) => {
                if (error.response)
                  message.message_modal(
                    error.response.data.title,
                    error.response.data.text,
                    error.response.status
                  );
              });
        }
      });
  };

  handleEliminar = (e) => {
    const index = e.currentTarget.getAttribute('data-index');
    let movimientos = [...this.state.movimientos];
    let movimientos_elimina = [...this.state.movimientos_elimina];

    const movimientoEliminado = movimientos.splice(index, 1)[0];
    movimientos_elimina.push(movimientoEliminado);

    this.setState({
      movimientos,
      movimientos_elimina,
    });
  };

  handleEliminar_agrega = (e) => {
    let movimientos_agrega = this.state.movimientos_agrega;
    let movimiento = {
      clave_concepto: parseInt(e.target.value.split("|")[1]),
      tipo_solicitud: parseInt(e.target.value.split("|")[0]),
      monto: e.target.value.split("|")[2],
    };
    for (let i = 0; i < movimientos_agrega.length; i++)
      if (JSON.stringify(movimiento) === JSON.stringify(movimientos_agrega[i]))
        movimientos_agrega.splice(i, 1);
    this.setState({
      movimientos_agrega,
    });
  };

  handleMovimiento = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_select_text = e[label];
    let movimiento_select_id = e[label_id];
    this.setState({ movimiento_select_text, movimiento_select_id });
  };

  handleMovimientoSol = (e) => {
    let label = e.name_text;
    let label_id = e.name_index;
    let movimiento_sol_anticipo_text = e[label];
    let movimiento_sol_anticipo_id = e[label_id];
    this.setState({ movimiento_sol_anticipo_text, movimiento_sol_anticipo_id });
  };

  handleAgrega = (e) => {
    let tipo = e.target.value;
    let message = "";

    const {
      movimiento_select_id,
      movimiento_sol_anticipo_id,
      importe,
      movimientos_new_sol,
    } = this.state;

    if (movimiento_select_id.length <= 0) {
      message += "Tipo de Movimiento, ";
    }
    if (movimiento_sol_anticipo_id.length <= 0) {
      message += "Movimiento, ";
    }
    if (tipo === "solicitud" && importe.length <= 0) {
      message += "Monto";
    }
    if (tipo === "movimiento" && importe.length <= 0) {
      message += "Monto";
    }
    if (message.length > 0) {
      swal("Falta completar los campos", message, "warning");
    } else {
      for (let i = 0; i < movimientos_new_sol.length; i++) {
        if (
          movimientos_new_sol[i].tipo_solicitud === movimiento_select_id &&
          movimientos_new_sol[i].clave_concepto === movimiento_sol_anticipo_id
        ) {
          swal(
            "Ya existe el movimiento seleccionado",
            "sólo puede haber un movimiento de cada tipo registrado",
            "warning"
          );
          return null;
        }
      }

      const newMovimiento = {
        tipo_solicitud: movimiento_select_id,
        clave_concepto: movimiento_sol_anticipo_id,
        monto: importe,
      };

      this.setState((prevState) => ({
        movimientos_new_sol: [...prevState.movimientos_new_sol, newMovimiento],
      }));
    }
  };

  handleDeleteNewMovimiento = (clave_concepto) => {
    const toFind = clave_concepto;
    const movimientos_new_sol = this.state.movimientos_new_sol.filter(
      (movimientos_new_sol) => movimientos_new_sol.clave_concepto !== toFind
    );
    this.setState({
      movimientos_new_sol: movimientos_new_sol,
    });
  };

  handleAgregaOLD = (e) => {
    let movimiento_select = parseInt(this.state.movimiento_sol_anticipo_id);
    let tipo_movimiento = this.state.movimiento_select_id;
    let monto_movimiento = document.getElementById(
      "monto_nueva_solicitud"
    ).value;
    let movimiento = {};
    let movimientos_agrega = this.state.movimientos_agrega;
    let movimientos = this.state.movimientos;
    let tamaño =
      tipo_movimiento === "0"
        ? this.props.ingresos.length
        : tipo_movimiento === "1"
        ? this.props.contribuciones.length
        : this.props.phxcc.length;
    let arreglo_select =
      tipo_movimiento === "0"
        ? this.props.ingresos
        : tipo_movimiento === "1"
        ? this.props.contribuciones
        : this.props.phxcc;
    for (let i = 0; i < tamaño; i++)
      if (arreglo_select[i].clave === movimiento_select) {
        movimiento["clave_concepto"] = arreglo_select[i].clave;
        movimiento["tipo_solicitud"] = tipo_movimiento;
        movimiento["monto"] = monto_movimiento;
      }
    for (let i = 0; i < movimientos.length; i++)
      if (
        movimiento.tipo_solicitud === movimientos[i].tipo_solicitud &&
        movimiento.clave_concepto.toString() === movimientos[i].clave_concepto
      ) {
        message.message_modal(
          "Advertencia",
          "Ya existe un movimiento igual",
          400
        );
        return;
      }
    for (let i = 0; i < movimientos_agrega.length; i++)
      if (
        movimiento.tipo_solicitud === movimientos_agrega[i].tipo_solicitud &&
        movimiento.clave_concepto === movimientos_agrega[i].clave_concepto
      ) {
        message.message_modal(
          "Advertencia",
          "Ya existe un movimiento igual",
          400
        );
        return;
      }
    movimientos_agrega.push(movimiento);
    this.setState({ movimientos_agrega });
  };

  methodPDF_API = (url, state, num) => {
    let url_intern = url + `${num}`;
    axios
      .get(url_intern, { responseType: "blob" })
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "alta_anticipo":
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);
              document.getElementById("dowload_pdf_alta_solicitud").href =
                fileURL;
              document.getElementById("window_pdf_alta_solicitud").href =
                fileURL;
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios
      .post(url_intern, data)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "solicitud":
              if (
                response.data.results.referencia !== null &&
                response.data.results.referencia !== undefined
              ) {
                // Si hay una referencia
                this.methodPDF_API(
                  utility.url_pdf_solicitud,
                  "alta_anticipo",
                  response.data.results.solicitud_ant[0].id_solicitud
                );
                this.setState(
                  {
                    // aduana_select: response.data.results.solicitud_ant[0].aduana,
                    // * Se obtiene la aduana de la patente
                    aduana_select: this.getAduana(response.data.results.patente),
                    referencia_select: response.data.results.referencia,
                    fecha_select: response.data.results.solicitud_ant[0].fecha,
                    importe_select: response.data.results.solicitud_ant[0].importe,
                    observaciones_select: response.data.results.solicitud_ant[0].observaciones,
                    movimientos: response.data.results.solicitud_ant[0].movimientos,
                    status: response.data.results.solicitud_ant[0].status,
                  },
                  () => {
                    this.methodPOST_API(
                      utility.url_consulta_referencia,
                      "cliente",
                      { referencia: this.state.referencia_select }
                    );
                  }
                );
                if (response.data.results.solicitud_ant[0].status === "A") {
                  this.setState({
                    enable_new_mov: false,
                    enable_referencia: false,
                    enable_observaciones: false,
                  });
                } else {
                  this.setState({
                    enable_new_mov: true,
                    enable_referencia: true,
                    enable_observaciones: true,
                  });
                }
                utility.add_class_disabled("nom_cliente_select");
                utility.add_class_disabled("num_cliente_select");
                utility.add_class_disabled("aduana_select");
                utility.add_class_disabled("ref_aduana_select");
                utility.add_class_disabled("fecha_select");
                utility.add_class_disabled("importe_select");
                utility.add_class_disabled("consulta_observaciones_sol");
                this.setState({ enable_pdf: false });
              } else {
                // No hay referencia
                this.setState({
                  referencia_select:
                    response.data.results.referencia !== null
                      ? response.data.results.referencia
                      : "Sin referencia",
                  fecha_select:
                    response.data.results.fecha !== null
                      ? response.data.results.fecha
                      : "",
                  importe_select:
                    response.data.results.importe !== null
                      ? response.data.results.importe
                      : "",
                  observaciones_select:
                    response.data.results.observaciones !== null
                      ? response.data.results.observaciones
                      : "",
                  movimientos:
                    response.data.results.movimientos !== null
                      ? response.data.results.movimientos
                      : "",
                  status:
                    response.data.results.status !== null
                      ? response.data.results.status
                      : "",
                  // aduana_select:
                  //   response.data.results.aduana !== null
                  //     ? response.data.results.aduana
                  //     : "-",
                  // * Sin aduana dado que no hay referencia
                  aduana_select: "-",
                  nom_cliente_select: "-",
                  num_cliente_select: "-",
                });
                this.methodPDF_API(
                  utility.url_pdf_solicitud,
                  "alta_anticipo",
                  response.data.results.id_solicitud
                );
                if (response.data.results.status === "A") {
                  this.setState({
                    enable_new_mov: false,
                    enable_referencia: false,
                    enable_observaciones: false,
                  });
                } else {
                  this.setState({
                    enable_new_mov: true,
                    enable_referencia: true,
                    enable_observaciones: true,
                  });
                }
                utility.add_class_disabled("nom_cliente_select");
                utility.add_class_disabled("num_cliente_select");
                utility.add_class_disabled("aduana_select");
                utility.add_class_disabled("ref_aduana_select");
                utility.add_class_disabled("fecha_select");
                utility.add_class_disabled("importe_select");
                utility.add_class_disabled("consulta_observaciones_sol");
                this.setState({ enable_pdf: false });
              }
              break;
            case "cliente":
              this.setState(
                {
                  num_cliente_select: response.data.results.cliente,
                  nom_cliente_select: utility.get_name_cliente(
                    response.data.results.cliente,
                    this.props.clientes.results
                  ),
                },
                () => {
                  this.methodPOST_API(utility.url_referencias, "referencias", {
                    numCliente: this.state.num_cliente_select,
                  });
                }
              );
              break;
            case "eliminar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            case "referencias":
              this.setState({
                all_referencias: response.data.results,
              });
              break;
            case "cancelar":
              this.handleCerrar();
              message.message_modal(
                response.data.title,
                response.data.text,
                response.status
              );
              this.props.update(1);
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  methodGET_API = (url, state) => {
    let url_intern = url;
    axios
      .get(url_intern)
      .then((response) => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case "ingresos":
              if (this._isMounted === true)
                this.setState({
                  arr_ingresos: response.data.results,
                  ingresos: response.data.results,
                });
              break;
            case "pagos":
              if (this._isMounted === true)
                this.setState({
                  arr_pagos: response.data.results,
                  contribuciones: response.data.results,
                });
              break;
            case "tercero":
              if (this._isMounted === true)
                this.setState({
                  arr_terceros: response.data.results,
                  phxcc: response.data.results,
                });
              break;
            case "clave_concepto":
              this.setState({
                clave_concepto: response.data.results,
              });
              break;
            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error.response)
          message.message_modal(
            error.response.data.title,
            error.response.data.text,
            error.response.status
          );
      });
  };

  onReferenciaChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = utility
        .get_referencias_cliente(
          this.state.num_cliente_select,
          this.state.all_referencias
        )
        .sort()
        .filter((v) => regex.test(v));
    }
    if (value === "") {
      this.setState({
        aduana_select: "",
      });
    }
    this.setState(
      {
        referencias_suggestions: suggestions,
        referencia_select: value,
        cambio_solicitud: 1,
      },
      () => {
        if (this.state.cambio_solicitud === 1) {
          this.setState({
            observaciones_select: "",
          });
        }
      }
    );
  };

  renderSuggestionReferencia = () => {
    if (this.state.referencias_suggestions.length === 0) return null;
    return (
      <ListGroup className="col-12" flush>
        {this.state.referencias_suggestions.map((data) => (
          <ListGroupItem
            key={data}
            onClick={() => this.suggestionSelectedReferecia(data)}
            action
          >
            {data}
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };

  suggestionSelectedReferecia = (value) => {
    let aduana = utility.get_aduana(
      value,
      utility.complete_number_cliente(this.state.num_cliente_select),
      this.state.all_referencias
    );
    if (aduana === undefined) aduana = "";
    this.setState({
      referencia_select: value,
      aduana_select: aduana,
      referencias_suggestions: [],
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.toogle_consulta}
        toggle={this.handleCerrar}
        backdrop="static"
        size="xl"
      >
        <ModalHeader toggle={this.handleCerrar}>
          Consulta Solicitud De Depósito
        </ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-10 col-8"
                nameLabel="Referencia o Número de Solicitud"
                id="numero_solicitud_anticipo_mov"
                onChange={this.handleNumber}
              />
              <Button
                color="primary"
                className="col-lg-2 col-4 mb-2"
                onClick={this.handleSolicitud}
              >
                Consulta
              </Button>
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Nombre del Cliente"
                id="nom_cliente_select"
                value={this.state.nom_cliente_select}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Número del Cliente"
                id="num_cliente_select"
                value={this.state.num_cliente_select}
                disabled
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-12"
                nameLabel="Aduana"
                id="aduana_select"
                value={this.state.aduana_select}
                disabled
              />
              {this.renderSuggestionReferencia()}
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Fecha de alta"
                id="fecha_select"
                value={this.state.fecha_select}
                disabled
              />
              <MyInput
                type="text"
                bootstrap="col-lg-6"
                nameLabel="Importe"
                id="importe_select"
                value={this.state.importe_select}
                disabled
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyTextArea
                bootstrap="col-lg-12 col-12"
                id="consulta_observaciones_sol"
                value={this.state.observaciones_select}
                onChange={this.handleObservacion}
                nameLabel="Observaciones"
                disabled = {this.state.enable_observaciones}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text="nombre"
                name_index="clave"
                id="tipo_movimiento_sol_antiicpo"
                nameLabel="Tipo de movimiento"
                value={this.state.movimiento_select_text}
                onChange={this.handleMovimiento}
                options={this.state.tipos_movimiento}
                disabled={this.state.enable_new_mov}
              />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text={
                  this.state.movimiento_select_id === "0"
                    ? "nombre"
                    : this.state.movimiento_select_id === "1"
                    ? "descripcion"
                    : this.state.movimiento_select_id === "2"
                    ? "nombre"
                    : null
                }
                name_index={
                  this.state.movimiento_select_id === "0"
                    ? "clave"
                    : this.state.movimiento_select_id === "1"
                    ? "id"
                    : this.state.movimiento_select_id === "2"
                    ? "clave"
                    : null
                }
                id="tipo_movimiento_sol_anticpo"
                nameLabel="Tipo de movimiento"
                disabled={this.state.enable_new_mov}
                value={this.state.movimiento_sol_anticipo_text}
                onChange={this.handleMovimientoSol}
                options={
                  this.state.movimiento_select_id === "0"
                    ? this.props.ingresos
                    : this.state.movimiento_select_id === "1"
                    ? this.props.contribuciones
                    : this.state.movimiento_select_id === "2"
                    ? this.props.phxcc
                    : []
                }
              />
              <MyInput
                type="text"
                bootstrap="col-lg-3"
                nameLabel="Monto"
                id="monto_nueva_solicitud"
                value={this.state.importe}
                onChange={this.handleImporte}
                onBlur={this.handleImporteBlur}
                disabled={this.state.enable_new_mov}
              />
              <Button
                color="primary"
                className="col-lg-1 col-4 mb-2"
                value="solicitud"
                disabled={this.state.enable_new_mov}
                onClick={this.handleAgrega}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </FormGroup>
          </Form>
          <Table size="sm" bordered hover responsive>
            <thead className="text-center header-table">
              <tr>
                <td>Tipo de Movimiento</td>
                <td>Concepto</td>
                <td>Cargo</td>
                <td></td>
              </tr>
            </thead>
            <tbody className="body-table">
              {this.state.movimientos.map((data, index) => (
                <tr key={index}>
                  <td>
                    <p className="text-center">
                      {utility.tipo_solicitud(data.tipo_solicitud)}
                    </p>
                  </td>
                  <td>
                    <p className="text-center">
                      {utility.get_type_concepto(
                        data.clave_concepto,
                        data.tipo_solicitud.toString(),
                        this.state.arr_ingresos,
                        this.state.arr_pagos,
                        this.state.arr_terceros
                      )}
                    </p>
                  </td>
                  <td>
                    <div style={{ width: "150px" }} className="mx-auto">
                      <Input
                        type="number"
                        id={`btn-${index}`}
                        value={data.monto}
                        step="0.001"
                        onChange={(e) => {
                          let movimientos = [...this.state.movimientos];
                          movimientos[index].monto = e.target.value;
                          this.setState({
                            movimientos: movimientos,
                            movimientos_edit: movimientos,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td className="p-1">
                    <div className="d-flex justify-content-center">
                      <Button
                        color="danger"
                        size="sm"
                        data-index={index}
                        onClick={this.handleEliminar}
                        disabled={this.state.enable_new_mov}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              {this.state.movimientos_new_sol.map((mov) => (
                <tr key={mov.clave_concepto}>
                  <td className="text-center">
                    {mov.tipo_solicitud === "0"
                      ? "Servicios"
                      : mov.tipo_solicitud === "1"
                      ? "Cuadro Liquidación"
                      : "Pagos Hechos por Cuenta del Cliente"}
                  </td>
                  <td className="text-center">
                    {utility.get_type_concepto(
                      mov.clave_concepto,
                      mov.tipo_solicitud,
                      this.props.ingresos,
                      this.props.contribuciones,
                      this.props.phxcc
                    )}
                  </td>
                  <td className="text-center">{mov.monto}</td>
                  <td className="text-center">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() =>
                        this.handleDeleteNewMovimiento(mov.clave_concepto)
                      }
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center">
            <LinkD
              href="#"
              id="dowload_pdf_alta_solicitud"
              download={`Solicitud de anticipo ${this.state.num_select}`}
            />
            <LinkV
              href="#"
              id="window_pdf_alta_solicitud"
              target={this.state.enable_pdf ? "_self" : "_blank"}
              className="ml-5"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "cancelar",
                this.props.permisos
              )
                ? false
                : true
            }
            onClick={this.handleCancelar}
          >
            Cancelar
          </Button>
          <Button
            color="info"
            disabled={
              this.state.status === "A" &&
              !permisos.obtener_permiso_especifico(
                "modificar",
                this.props.permisos
              )
                ? false
                : true
            }
            onClick={this.handleModificacion}
          >
            Modificar
          </Button>
          <Button color="primary" onClick={this.handleCerrar}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalConsulta;
