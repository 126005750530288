import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormText } from 'reactstrap';

function MyDropzoneMult(props) {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0)
      props.handleArchivo(acceptedFiles);
  }, [props]);

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: props.accept // Aquí usamos la prop accept proporcionada por el componente padre
  });

  return (
    <div {...getRootProps()} className="col-12 dropzone-container">
      <input {...getInputProps()} id="archivos" />
      <p className="mb-0 text-center">{
        isDragActive ?
          'Suelte sus archivos aquí' :
          'Seleccione o arraste sus archivos'
      }<br />
        <FormText color="muted" className="d-block">
          {props.accept.includes(".xml")
            ? "Sólo archivos con extensión .xml, .pdf, .jpg, .png y .jpeg serán aceptados"
            : "Sólo archivos con extensión .pdf, .jpg, .png y .jpeg serán aceptados"}
        </FormText>
      </p>
    </div>
  )
}

export default MyDropzoneMult;