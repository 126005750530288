/* 
  Autor: Alexis Yael Hernández Grimaldo.
  Descripción: Componente único para el modal, genera la alta de un anticipo. 
*/
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup } from 'reactstrap';
import * as utility from '../utils/utility';
import * as message from '../messages/messages';
import axios from 'axios';
import swal from '@sweetalert/with-react';
import WebSocketInstance from "../../../utils/websocket";
import MyInput from '../../../utils/custom/MyInput';
import MyTextArea from '../../../utils/custom/MyTextArea';
import CustomSelect from '../../../utils/custom/CustomSelect';

class ModalAlta extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      estados: [],
      identificativo: "",

      domicilio: "",
      colonia_id: "",
      colonia_text: "Seleccione una colonia",
      codigo_postal: "",
      estado: "",
      ciudad: "",

      colonias: [],
    }
  }
  /*--------------------------------------------------------------------- */
  /*--------------- FUNCIONES PARA EL USO DE SOCKETS ---------------------*/
  sendNotificacionHandler = (motivo) => {
    let folio = this.state.identificativo;
    const notificacionObject = {
      tipo: 0,
      aplicacion: 'consolidadora',
      motivo: motivo,
      folio: [folio]
    }
    WebSocketInstance.newNotificacion(notificacionObject);
  }
  /*--------------------------------------------------------------------- */
  componentDidMount() {
    this._isMounted = true;
    this.methodGET_API(utility.url_estados, 'estados');
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDomicilio = (e) => {
    let domicilio = e.target.value;
    this.setState({ domicilio });
  }
  handleColonia = (e) => {
    let estado, ciudad;
    let label_id = e.name_index;
    let label = e.name_text;
    let colonia_text = e[label];
    let colonia_id = e[label_id];
    this.state.colonias.forEach(col => {
      if (colonia_id === col.asentamiento) {
        estado = col.estado;
        ciudad = col.ciudad;
      }
    })
    this.setState({ colonia_text, colonia_id, estado, ciudad });
  }
  handleCodigo_postal = (e) => {
    let codigo_postal = e.target.value;
    if (codigo_postal.length === 5)
      this.methodPOST_API(utility.url_codigo_postal, 'codigo_postal', { codigo: codigo_postal });
    this.setState({ codigo_postal });
  }
  /**
   * Función que permite hacer una petición de forma GET
   * @param {String} url URL a consulta
   * @param {String} state Opción del case
   */
  methodGET_API = (url, state) => {
    let url_intern = url;
    axios.get(url_intern)
      .then(response => {
        if (response.status >= 200 && response.status < 400)
          switch (state) {
            case 'estados':
              if (this._isMounted)
                this.setState({ estados: response.data.results });
              break;
            default:
              break;
          }
      })
      .catch(error => {
        if (error.response)
          message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
      });
  }
  methodPOST_API = (url, state, data) => {
    let url_intern = url;
    axios.post(url_intern, data)
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          switch (state) {
            case 'alta_consolidadora':
              this.setState({ identificativo: response.data.results.numero }, () => {
                this.sendNotificacionHandler('crear');
              })
              this.props.toogleNuevo();
              message.message_modal(response.data.title, response.data.text, response.status);
              this.props.update(1);
              break;
            case 'codigo_postal':
              if (this._isMounted)
                this.setState({
                  colonias: response.data.results
                });
              break;
            default:
              break;
          }
        }
      })
      .catch(error => {
        if (error.response)
          message.message_modal(error.response.data.title, error.response.data.text, error.response.status);
      });
  }
  handleAlta = () => {
    message.message_confimacion('¿Desea continuar con la operación alta?', '')
      .then(res => {
        if (res) {
          let coloniaValue = "";
          if (document.getElementById('colonias_consolidadora_alta'))
            coloniaValue = document.getElementById('colonias_consolidadora_alta').value;
          let data = {
            nombre: document.getElementById('nombre_consolidadora_alta').value,
            rfc: document.getElementById('rfc_consolidadora_alta').value,
            contacto: document.getElementById('contacto_consolidadora_alta').value,
            domicilio: document.getElementById('domicilio_consolidadora_alta').value,
            colonia: coloniaValue,
            ciudad: document.getElementById('ciudad_consolidadora_alta').value,
            estado: document.getElementById('estado_consolidadora_alta').value,
            codigo_postal: document.getElementById('cp_consolidadora_alta').value,
            telefono: document.getElementById('telefono_consolidadora_alta').value,
            horario_guias: document.getElementById('hguiaI_consolidadora_alta').value + ' A ' + document.getElementById('hguiaF_consolidadora_alta').value,
            horario_pagos: document.getElementById('hpagoI_consolidadora_alta').value + ' A ' + document.getElementById('hpagoF_consolidadora_alta').value,
            correo: document.getElementById('correo_consolidadora_alta').value,
            pagina: document.getElementById('pagina_consolidadora_alta').value,
            comentario: document.getElementById('observaciones_consolidadora_alta').value,
          }
          if (this.handleValida(data) === 0)
            return null;
          this.methodPOST_API(utility.url_crear_consolidadora, 'alta_consolidadora', data);
        }
      });
  }
  handleValida = (data) => {
    let message = "";
    if (!data.nombre.length)
      message += "Nombre, ";
    if (!data.rfc.length)
      // message += "RFC, ";
      delete data.rfc;
    if (!data.contacto.length)
      message += "Contacto.";
    if (!data.domicilio.length)
      // message += "Domicilio, ";
      delete data.domicilio;
    if (!data.colonia.length)
      // message += "Colonia, ";
      delete data.colonia;
    if (!data.ciudad.length)
      // message += "Ciudad, ";
      delete data.ciudad;
    if (!data.estado.length)
      // message += "Estado, ";
      delete data.estado;
    if (!data.codigo_postal.length)
      // message += "Código Postal, ";
      delete data.codigo_postal;
    if (!data.comentario.length)
      message += "Comentario.";
      // delete data.comentario;
    if (!data.telefono.length)
      delete data.telefono;
    if (data.horario_guias.length < 4)
      delete data.horario_guias;
    if (data.horario_pagos.length < 4)
      delete data.horario_pagos;
    if (!data.correo.length)
      delete data.correo;
    if (!data.pagina.length)
      delete data.pagina;
    if (message.length > 0) {
      swal("LLenar los campos: ", message, "warning");
      return 0;
    }
    return 1
  }
  handleCerrar = () => {
    this.setState({
      estados: [],
      identificativo: "",

      domicilio: "",
      colonia_id: "",
      colonia_text: "Seleccione una colonia",
      codigo_postal: "",
      estado: "",
      ciudad: "",

      colonias: [],
    });
    this.props.toogleNuevo();
  }
  render() {
    return (
      <Modal isOpen={this.props.toogle_nuevo} toggle={this.handleCerrar} backdrop="static" size="xl">
        <ModalHeader toggle={this.handleCerrar}>Alta Consolidadora</ModalHeader>
        <ModalBody>
          <Form className="container-fluid">
            <FormGroup className="row mb-1">
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Nombre de Consolidadora" id="nombre_consolidadora_alta" />
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="RFC" id="rfc_consolidadora_alta" />
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Contacto" id="contacto_consolidadora_alta" />
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Telefono" id="telefono_consolidadora_alta" />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput type="text" bootstrap="col-lg-4 col-12" id="domicilio_consolidadora_alta" nameLabel="Domicilio"
                value={this.state.domicilio} onChange={this.handleDomicilio} />
              <MyInput type="text" bootstrap="col-lg-4 col-12" id="cp_consolidadora_alta" nameLabel="Código Postal"
                value={this.state.codigo_postal} onChange={this.handleCodigo_postal} />
              <CustomSelect
                bootstrap="col-lg-4 col-12"
                name_text='asentamiento'
                name_index='asentamiento'
                id="colonia_consolidadora_alta"
                nameLabel="Colonia"
                value={this.state.colonia_text}
                onChange={this.handleColonia}
                options={this.state.colonias}
              />
            </FormGroup>
            <FormGroup className="row mb-0">
              <MyInput type="text" bootstrap="col-lg-6 col-12" id="estado_consolidadora_alta" nameLabel="Estado/Provincia/Región"
                value={this.state.estado} disabled />
              <MyInput type="text" bootstrap="col-lg-6 col-12" id="ciudad_consolidadora_alta" nameLabel="Ciudad"
                value={this.state.ciudad} disabled />
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Guias (Desde)" id="hguiaI_consolidadora_alta" />
              <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Guias (Hasta)" id="hguiaF_consolidadora_alta" />
              <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Pago (Desde)" id="hpagoI_consolidadora_alta" />
              <MyInput type="time" bootstrap="col-lg-3 col-12" nameLabel="Horario de Pago (Hasta)" id="hpagoF_consolidadora_alta" />
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Correo" id="correo_consolidadora_alta" />
              <MyInput type="text" bootstrap="col-lg-6 col-12" nameLabel="Página" id="pagina_consolidadora_alta" />
            </FormGroup>
            <FormGroup className="row mb-1">
              <MyTextArea bootstrap="col-12" id="observaciones_consolidadora_alta" nameLabel="Observaciones" />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={this.handleAlta}>Aceptar</Button>
          <Button color="primary" onClick={this.handleCerrar}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalAlta;